<template>
    <app-layout title="Login">
        <Head>
            <title>Login | Qariin</title>
        </Head>
        <jet-authentication-card>
            <jet-validation-errors class="mb-4" />

            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>

            <div class="container flex justify-center mt-16 text-center">
                <form @submit.prevent="submit">
                    <h1 class="text-2xl lg:text-3xl">تسجيل الدخول</h1>
                    <p class="text-sm">
                        <span class="text-secondary-500">ليس لديك حساب؟</span>
                        <a
                            :href="route('register')"
                            class="text-primary-500 ms-2"
                            >انشئ حسابًا مجانيًا</a
                        >
                    </p>
                    <fieldset class="input email">
                        <jet-label
                            for="email"
                            value="البريد الالكتروني او رقم الهاتف"
                        />
                        <jet-input
                            type="text"
                            id="text"
                            required
                            name="login"
                            v-model="form.login"
                            autocomplete="off"
                            placeholder="اكتب البريد الالكتروني او رقم الهاتف هنا..."
                            autofocus
                        />
                    </fieldset>

                    <fieldset class="input password">
                        <jet-label for="password">كلمة المرور</jet-label>
                        <jet-input
                            type="password"
                            id="password"
                            required
                            name="password"
                            placeholder="اكتب كلمة المرور هنا..."
                            autocomplete="off"
                            v-model="form.password"
                        />
                    </fieldset>

                    <jet-button
                        type="submit"
                        class="bg-primary text-white w-64 rounded-md"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                        >تسجيل دخول</jet-button
                    >

                    <div class="flex items-center justify-between">
                        <fieldset>
                            <jet-checkbox
                                id="remember-me"
                                name="remember"
                                v-model:checked="form.remember"
                            />
                            <label for="remember-me" class="text-xs"
                                >تذكرني</label
                            >
                        </fieldset>
                        <Link
                            v-if="canResetPassword"
                            :href="route('password.request')"
                            class="text-xs"
                        >
                            هل نسيت كلمة المرور؟
                        </Link>
                    </div>
                </form>
            </div>
        </jet-authentication-card>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import { defineComponent } from "vue";
import JetAuthenticationCard from "@/Jetstream/AuthenticationCard.vue";
import JetButton from "@/Jetstream/Button.vue";
import JetInput from "@/Jetstream/Input.vue";
import JetCheckbox from "@/Jetstream/Checkbox.vue";
import JetLabel from "@/Jetstream/Label.vue";
import JetValidationErrors from "@/Jetstream/ValidationErrors.vue";
import { Link } from "@inertiajs/inertia-vue3";
import AppLayout from "@/Layouts/AppLayout.vue";

export default defineComponent({
    components: {
        AppLayout,
        Head,
        JetAuthenticationCard,
        JetButton,
        JetInput,
        JetCheckbox,
        JetLabel,
        JetValidationErrors,
        Link,
    },

    props: {
        canResetPassword: Boolean,
        status: String,
    },

    data() {
        return {
            form: this.$inertia.form({
                login: "",
                password: "",
                remember: false,
            }),
        };
    },

    methods: {
        submit() {
            this.form
                .transform((data) => ({
                    ...data,
                    remember: this.form.remember ? "on" : "",
                }))
                .post(this.route("login"), {
                    onFinish: () => this.form.reset("password"),
                });
        },
    },
    mounted() {
        localStorage.setItem('otp_reason', 'login-otp');
    }
});
</script>
