<template>
    <Modal wide ref="modal" title="إضافة معلومات اخرى">
        <form v-if="!loading" @submit.prevent="raiseData()">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="vehicle-location"
                        >مكان إيقاف المركبة في الليل</label
                    >
                    <select
                        v-model="input.parking_location_code"
                        name="vehicle-location"
                        id="vehicle-location"
                    >
                        <option value="null" disabled>اختر...</option>
                        <option
                            v-for="location in locations"
                            :key="location.code"
                            :value="location.code"
                        >
                            {{ location.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="accidents-num">عدد الحوادث</label>
                    <select
                        v-model="input.accidents_number"
                        name="accidents-num"
                        id="accidents-num"
                    >
                        <option value="null" >لا يوجد حوادث في أخر 5 سنوات</option>
                        <option
                            v-for="num in 10"
                            :key="num"
                            :value="num"
                        >
                            {{ num }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="expected-km"
                        >الكيلومترات المتوقع قطعها كل سنة</label
                    >
                    <select
                        v-model="input.mileage_code"
                        name="expected-km"
                        id="expected-km"
                    >
                        <option value="null" disabled>اختر...</option>
                        <option
                            v-for="item in distancesPerYear"
                            :key="item.code"
                            :value="item.code"
                        >
                            {{ item.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="gear-type">نوع ناقل الحركة</label>
                    <select
                        v-model="input.transmission_type_code"
                        name="gear-type"
                        id="gear-type"
                    >
                        <option value="null" disabled>اختر...</option>
                        <option
                            v-for="type in transmissionTypes"
                            :key="type.code"
                            :value="type.code"
                        >
                            {{ type.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="education">التعليم</label>
                    <select
                        v-model="input.educational_level_code"
                        name="education"
                        id="vehicle-modifications"
                    >
                        <option value="null" disabled>اختر...</option>
                        <option
                            v-for="education in educations"
                            :key="education.code"
                            :value="education.code"
                        >
                            {{ education.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="childs">عدد الأطفال دون عمر ال 16</label>
                    <select
                        v-model="input.children_under_sixteen"
                        name="childs"
                        id="childs"
                    >
                        <option value="null">لا يوجد أبناء دون عمر 16 سنة</option>
                        <option
                            v-for="num in 5"
                            :key="num"
                            :value="num"
                        >
                            {{ num < 5 ? num : "5 أو اكثر" }}
                        </option>
                    </select>
                </fieldset>
                <fieldset
                    class="selectbox"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                            .default
                    }');`"
                >
                    <label for="work">عنوان جهة العمل</label>
                    <select v-model="input.city_code" name="city" id="city">
                        <option value="null" disabled>اختر...</option>
                        <option
                            v-for="item in cities"
                            :key="item.code"
                            :value="item.code"
                        >
                            {{ item.arabic_description }}
                        </option>
                    </select>
                </fieldset>
                <fieldset class="input-normal mx-auto">
                    <label for="vehicle-modifications"
                        >هل يوجد تعديلات على المركبة؟</label
                    >
                    <input
                        v-model="input.vehicle_modifications"
                        type="text"
                        id="vehicle-modifications"
                        name="vehicle-modifications"
                        placeholder="هل يوجد تعديلات على المركبة؟"
                    />
                </fieldset>
            </div>
            <div class="modals__addons">
                <button
                    type="button"
                    class="modals__addons__button"
                    @click="toggleAbroadLicenses()"
                >
                    <span v-if="!showAbroadLicenses" class="icon">+</span>
                    <span v-else class="icon">-</span>
                    <span>
                        هل لدى مالك الوثيقة رخصة قيادة صالحة من دول أخرى؟
                    </span>
                </button>
                <div v-if="showAbroadLicenses" class="px-xs">
                    <div
                        class="grid grid-cols-1 md:grid-cols-3 gap-3 place-content-start"
                        v-for="(item, index) in input.another_country_licenses"
                        :key="index"
                    >
                        <fieldset
                            class="selectbox"
                            :style="`--icon: url('${
                                require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                    .default
                            }');`"
                        >
                            <label for="education">الدوله</label>
                            <select
                                v-model="
                                    input.another_country_licenses[index]
                                        .country_code
                                "
                                name="education"
                                id="vehicle-modifications"
                            >
                                <option value="null" disabled>اختر...</option>
                                <option
                                    v-for="country in countries"
                                    :key="country.code"
                                    :value="country.code"
                                >
                                    {{
                                        country.arabic_description
                                            ? country.arabic_description
                                            : country.english_description
                                    }}
                                </option>
                            </select>
                        </fieldset>
                        <fieldset
                            class="selectbox"
                            :style="`--icon: url('${
                                require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                    .default
                            }');`"
                        >
                            <label for="accidents-num">عدد سنوات الرخصه</label>
                            <select
                                v-model="
                                    input.another_country_licenses[index].years
                                "
                                name="accidents-num"
                                id="accidents-num"
                            >
                                <option value="null" disabled>اختر...</option>
                                <option
                                    v-for="num in 20"
                                    :key="num"
                                    :value="num"
                                >
                                    {{ num }}
                                </option>
                            </select>
                        </fieldset>
                        <div>
                            <button
                                type="button"
                                class="modals__addons__button modals__addons__button--red whitespace-nowrap"
                                @click="removeLicense(index)"
                            >
                                <span class="icon">-</span>
                                <span> حذف الرخصه </span>
                            </button>
                        </div>
                    </div>
                    <div class="mt-2 mb-6">
                        <button
                            type="button"
                            class="modals__addons__button modals__addons__button--green"
                            @click="addLicense()"
                        >
                            <span class="icon">+</span>
                            <span> إضافة رخصه اخرى </span>
                        </button>
                    </div>
                </div>
                <button
                    type="button"
                    class="modals__addons__button"
                    @click="toggleMedicalConditions()"
                >
                    <span v-if="!showMedicalConditions" class="icon">+</span>
                    <span v-else class="icon">-</span>
                    <span
                        >هل لدى مالك الوثيقة ظروف صحية أو قيود على الرخصة؟</span
                    >
                </button>
                <div v-if="showMedicalConditions" class="px-xs mb-6 mt-2">
                    <fieldset
                        class="selectbox"
                        :style="`--icon: url('${
                            require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                .default
                        }');`"
                    >
                        <label for="education"
                            >الظروف الصحية او القويد على الرخصه</label
                        >
                        <select
                            v-model="input.medical_condition_code"
                            name="education"
                            id="vehicle-modifications"
                        >
                            <option value="null" disabled>اختر...</option>
                            <option
                                v-for="condition in medicalConditions"
                                :key="condition.code"
                                :value="condition.code"
                            >
                                {{ condition.arabic_description }}
                            </option>
                        </select>
                    </fieldset>
                </div>
                <button
                    type="button"
                    class="modals__addons__button"
                    @click="toggleTrafficViolations"
                >
                    <span v-if="!showTrafficViolations" class="icon">+</span>
                    <span v-else class="icon">-</span>
                    <span>هل لدى مالك الوثيقة مخالفات مرورية؟</span>
                </button>
                <div
                    v-if="showTrafficViolations"
                    class="grid grid-cols-1 md:grid-cols-3 gap-3 px-xs"
                >
                    <fieldset
                        v-for="(violation, index) in violations"
                        :key="violation.code"
                        class="max-w-xs mb-3"
                    >
                        <input
                            type="checkbox"
                            :id="`violation#${index}`"
                            :checked="input.violations.includes(violation.code)"
                            @change="toggleViolation(violation.code)"
                        />
                        <label :for="`violation#${index}`" class="text-xs">
                            {{ violation.arabic_description }}
                        </label>
                    </fieldset>
                </div>
            </div>
            <div class="flex items-center justify-between gap-4">
                <button
                    type="button"
                    class="text-center bg-white text-primary rounded-md px-md"
                    @click="resetData"
                >
                    إعاده تعيين البيانات
                </button>
                <button
                    type="submit"
                    class="text-center bg-primary text-white rounded-md px-md"
                >
                    حفظ التغيرات
                </button>
            </div>
        </form>

        <div v-else class="flex items-center justify-center my-16">
            <VueLottiePlayer
                style="width: 200px; height: 100px"
                name="Qariin loader"
                loop
                path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
            />
        </div>
    </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "@/components/Modal";
import axios from "axios";
import VueLottiePlayer from "vue-lottie-player";

export default defineComponent({
    name: "AdditionalInfoModal",
    components: {
        Modal,
        VueLottiePlayer,
    },
    props: {
        otherInfo: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            open: false,
            loading: false,
            dataCleared: false,
            input: {
                have_other_info: 1,
                parking_location_code: null,
                accidents_number: null,
                mileage_code: null,
                have_another_country_licenses: 0,
                transmission_type_code: null,
                educational_level_code: null,
                children_under_sixteen: null,
                city_code: null,
                vehicle_modifications: null,
                violations: [],
                another_country_licenses: [],
                medical_condition_code: null,
            },
            showAbroadLicenses: false,
            showTrafficViolations: false,
            showMedicalConditions: false,
            locations: [],
            distancesPerYear: [],
            transmissionTypes: [],
            educations: [],
            countries: [],
            cities: [],
            violations: [],
            medicalConditions: [],
        };
    },
    watch: {
        "input.another_country_licenses": {
            handler() {
                const { another_country_licenses } = this.input;
                if (
                    another_country_licenses.length &&
                    another_country_licenses[0].country_code &&
                    another_country_licenses[0].years
                )
                    this.input.have_another_country_licenses = 1;
                else this.input.have_another_country_licenses = 0;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        toggleModal() {
            this.$refs.modal.toggleModal();
        },
        toggleAbroadLicenses() {
            this.showAbroadLicenses = !this.showAbroadLicenses;
        },
        toggleTrafficViolations() {
            this.showTrafficViolations = !this.showTrafficViolations;
        },
        toggleMedicalConditions() {
            this.showMedicalConditions = !this.showMedicalConditions;
        },
        addLicense() {
            this.input.another_country_licenses.push({
                country_code: null,
                years: null,
            });
        },
        removeLicense(index) {
            if (!this.input.another_country_licenses.length) return;
            this.input.another_country_licenses.splice(index, 1);
        },
        toggleViolation(code) {
            if (this.input.violations.includes(code))
                this.input.violations.splice(
                    this.input.violations.indexOf(code),
                    1
                );
            else this.input.violations.push(code);
        },
        async getParkingLocations() {
            await axios
                .get("/api/lookups/parking_locations")
                .then(({ data }) => {
                    this.locations = data.data;
                });
        },
        async getExpectedDistancePerYear() {
            await axios.get("/api/lookups/mileages").then(({ data }) => {
                this.distancesPerYear = data.data;
            });
        },
        async getTransmissionTypes() {
            await axios
                .get("/api/lookups/transmission_types")
                .then(({ data }) => {
                    this.transmissionTypes = data.data;
                });
        },
        async getEducations() {
            await axios.get("/api/lookups/education").then(({ data }) => {
                this.educations = data.data;
            });
        },
        async getCountries() {
            await axios.get("/api/lookups/countries").then(({ data }) => {
                this.countries = data.data;
            });
        },
        async getCities() {
            await axios.get("/api/lookups/cities").then(({ data }) => {
                this.cities = data.data;
            });
        },
        async getViolations() {
            await axios.get("/api/lookups/violations").then(({ data }) => {
                this.violations = data.data;
            });
        },
        async getMedicalConditions() {
            await axios
                .get("/api/lookups/medical_conditions")
                .then(({ data }) => {
                    this.medicalConditions = data.data;
                });
        },
        raiseData() {
            let data = {};
            if (this.dataCleared) {
                data.clear_other_info = 1;
            } else {
                data = { ...this.input };

                Object.keys(data).forEach((key) => {
                    if (!data[key]) delete data[key];
                });
            }

            // eslint-disable-next-line vue/require-explicit-emits
            this.$emit("saved", data);
            this.toggleModal();
        },
        resetData() {
            Object.keys(this.input).forEach((key) => {
                this.input[key] = null;
            });

            this.input.violations = [];
            this.input.another_country_licenses = [];

            this.dataCleared = true;
        },
    },
    async mounted() {
        this.loading = true;
        await this.getParkingLocations();
        await this.getExpectedDistancePerYear();
        await this.getTransmissionTypes();
        await this.getEducations();
        await this.getCountries();
        await this.getCities();
        await this.getViolations();
        await this.getMedicalConditions();

        if (this.otherInfo && this.otherInfo.id) {
            Object.keys(this.otherInfo).forEach((key) => {
                if (key != "violations" && key != "other_licenses") {
                    this.input[key] = this.otherInfo[key];
                }
            });

            if (this.otherInfo.other_licenses.length) {
                this.otherInfo.other_licenses.forEach((license) => {
                    this.input.another_country_licenses.push({
                        country_code: license.country_code,
                        years: license.years,
                    });
                });
            }

            if (this.otherInfo.violations.length) {
                this.otherInfo.violations.forEach((violation) => {
                    this.input.violations.push(violation.violation_code);
                });
            }
        }

        this.loading = false;
    },
});
</script>
