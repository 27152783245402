<template>
    <app-layout v-if="content.vehicle">
        <Head>
            <title>Offers | Qariin</title>
        </Head>
        <Modal ref="filterCompanies" title="فلتر شركات التامين" small>
            <div>
                <fieldset
                    v-for="(company, index) in companies"
                    :key="company.id"
                    class="max-w-xs mb-3"
                >
                    <input
                        :checked="selectedCompanies.includes((company.id).toString())"
                        type="checkbox"
                        :id="`${company.id}#${index}`"
                        @change="filterCompanies(company)"
                    />
                    <label :for="`${company.id}#${index}`" class="text-xs ps-1">
                        {{ company.name_ar }}
                    </label>
                </fieldset>
                <button
                    class="px-xs bg-primary text-white rounded-md min-h-full py-1 px-sm"
                    :class="{ disabled: selectedCompanies.length == companiesLengthInSearchParams }"
                    @click="sendFilters(selectedCompanies.length != companiesLengthInSearchParams)"
                >
                    حفظ
                </button>
                <button
                    v-if="(selectedCompanies.length == companiesLengthInSearchParams) && (companiesLengthInSearchParams > 0)"
                    class="px-xs bg-primary-300 text-primary-900 rounded-md min-h-full py-1 px-sm ms-3"
                    @click="resetFilters"
                >
                    إعادة الوضع الأصلي
                </button>
            </div>
        </Modal>
        <ErrorsAlert
            v-if="errorMessages && errorMessages.length"
            :errors="errorMessages"
        />
        <NCDTableModal ref="ncdTable" :data="content.ncd_result" />
        <AdditionalInfoModal
            v-if="additional_info"
            ref="additional_info"
            :other-info="other_info"
            @saved="saveAdditionalData($event)"
        />
        <AddDriversModal
            v-if="add_drivers"
            ref="add_drivers"
            :current-hijri-year="currentHijriYear"
            :added-drivers="content.vehicle.drivers"
            @saved="saveAdditionalDriversData($event)"
        />
        <section>
            <div class="container">
                <div class="offers__vehicle">
                    <h1 class="text-xl lg:text-3xl text-black">
                        بيانات المركبة
                    </h1>
                    <div class="offers__addons">
                        <button
                            v-if="false"
                            data-modal="promotional-programs"
                            class="offers__addons__button"
                        >
                            <span class="icon">+</span>
                            <span>البرامج الترويجية</span>
                        </button>
                        <button
                            data-modal="add-more-info"
                            class="offers__addons__button"
                            @click="openAdditionalData"
                        >
                            <span class="icon">+</span>
                            <span>إضافة معلومات اخري</span>
                        </button>
                        <button
                            data-modal="add-driver"
                            class="offers__addons__button"
                            @click="openAddDrivers"
                        >
                            <span class="icon">+</span>
                            <span>هل ترغب بإضافة سائق؟</span>
                        </button>
                        <form
                            v-if="
                                additionalInfoApplied ||
                                additionalDriversDataApplied ||
                                driversCleared
                            "
                            :action="route('quotations.apply.other.infos')"
                            method="post"
                        >
                            <template v-if="additionalInfoApplied">
                                <template
                                    v-for="(key, index) in Object.keys(
                                        additionalInfo
                                    )"
                                    :key="index"
                                >
                                    <template
                                        v-if="key == 'another_country_licenses'"
                                    >
                                        <template
                                            v-for="(
                                                license, i
                                            ) in additionalInfo[key]"
                                            :key="i"
                                        >
                                            <input
                                                type="hidden"
                                                :name="`otherInfo[another_country_licenses][${i}][country_code]`"
                                                :value="license.country_code"
                                            />
                                            <input
                                                type="hidden"
                                                :name="`otherInfo[another_country_licenses][${i}][years]`"
                                                :value="license.years"
                                            />
                                        </template>
                                    </template>
                                    <template v-else-if="key == 'violations'">
                                        <template
                                            v-for="(
                                                violation, i
                                            ) in additionalInfo[key]"
                                            :key="i"
                                        >
                                            <input
                                                type="hidden"
                                                :name="`otherInfo[violations][${i}]`"
                                                :value="violation"
                                            />
                                        </template>
                                    </template>
                                    <input
                                        v-else-if="key == 'have_other_info'"
                                        type="hidden"
                                        :name="key"
                                        :value="additionalInfo[key]"
                                    />
                                    <input
                                        v-else
                                        type="hidden"
                                        :name="`otherInfo[${key}]`"
                                        :value="additionalInfo[key]"
                                    />
                                </template>
                            </template>
                            <template
                                v-if="
                                    !driversCleared &&
                                    additionalDriversData.length
                                "
                            >
                                <template
                                    v-for="(driver, k) in additionalDriversData"
                                    :key="k"
                                >
                                    <template
                                        v-for="(key, index) in Object.keys(
                                            driver
                                        )"
                                        :key="index"
                                    >
                                        <template
                                            v-if="
                                                key ==
                                                'another_country_licenses'
                                            "
                                        >
                                            <template
                                                v-for="(license, i) in driver[
                                                    key
                                                ]"
                                                :key="i"
                                            >
                                                <input
                                                    type="hidden"
                                                    :name="`drivers[${k}][another_country_licenses][${i}][country_code]`"
                                                    :value="
                                                        license.country_code
                                                    "
                                                />
                                                <input
                                                    type="hidden"
                                                    :name="`drivers[${k}][another_country_licenses][${i}][years]`"
                                                    :value="license.years"
                                                />
                                            </template>
                                        </template>
                                        <template
                                            v-else-if="key == 'violations'"
                                        >
                                            <template
                                                v-for="(violation, i) in driver[
                                                    key
                                                ]"
                                                :key="i"
                                            >
                                                <input
                                                    type="hidden"
                                                    :name="`drivers[${k}][violations][${i}]`"
                                                    :value="violation"
                                                />
                                            </template>
                                        </template>
                                        <input
                                            v-else
                                            type="hidden"
                                            :name="`drivers[${k}][${key}]`"
                                            :value="driver[key]"
                                        />
                                    </template>
                                </template>
                            </template>
                            <template v-if="driversCleared">
                                <input
                                    v-for="(driver, index) in deletedDrivers"
                                    :key="driver.id"
                                    type="hidden"
                                    :name="`deleted_drivers[${index}]`"
                                    :value="driver"
                                />
                            </template>
                            <input
                                type="hidden"
                                name="search_id"
                                :value="searchId"
                            />
                            <input
                                type="hidden"
                                name="vehicle_id"
                                :value="content.vehicle.id"
                            />
                            <input type="hidden" name="_token" :value="csrf" />
                            <input
                                type="hidden"
                                name="pageUrl"
                                :value="`${route('offers')}?id=${searchId}`"
                            />
                            <button
                                type="submit"
                                data-modal="add-driver"
                                class="offers__addons__button"
                            >
                                <span class="icon">
                                    <img
                                        class="w-3 h-3 mt-1"
                                        :src="
                                            require('@qariin-fe/src/images/input-icons/reload.svg')
                                                .default
                                        "
                                        alt="Reload"
                                    />
                                </span>
                                <span> اعادة تحميل العروض </span>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="offers__vehicle__info">
                    <article class="pt-2">
                        <img
                            :src="getImgUrl(content.vehicle.car_logo_path)"
                            class="w-12 h-12 object-contain me-3"
                            alt="Car logo"
                        />
                        <div>
                            <span class="test-secondary text-xs"
                                >وصف المركبة</span
                            >
                            <p class="text-black text-sm">
                                {{ content.vehicle.maker.name }}
                                {{ content.vehicle.vehicle_model?.name }}
                                {{ content.vehicle.model_year }}
                                {{ content.vehicle.major_color }}
                            </p>
                        </div>
                    </article>
                    <article v-if="content.vehicle?.owner_name">
                        <div>
                            <span class="test-secondary text-xs"
                                >اسم مالك السيارة</span
                            >
                            <p class="text-black text-sm">
                                {{ content.vehicle.owner_name }}
                            </p>
                        </div>
                    </article>
                    <article>
                        <div
                            class="cursor-pointer"
                            @click="$refs.ncdTable.toggleModal()"
                        >
                            <span class="test-secondary text-xs"
                                >استحقاق الخصم</span
                            >
                            <template
                                v-for="ncd in content.ncd_result"
                                :key="ncd.id"
                            >
                                <p
                                    v-if="ncd.applied"
                                    class="text-black text-sm"
                                >
                                    {{ ncd.years }}
                                </p>
                            </template>
                            <p v-if="!hasNcdApplied" class="text-black text-sm">
                                لا يوجد
                            </p>
                        </div>
                    </article>
                    <article>
                        <div>
                            <span class="test-secondary text-xs"
                                >قيمة المركبة التقديرية</span
                            >
                            <p class="text-black text-sm">
                                {{ content.vehicle.vehicle_value }}
                            </p>
                        </div>
                    </article>
                    <article>
                        <figure
                            v-if="content.vehicle && content.vehicle.plate_number"
                            class="offers__plate"
                            :data-plate-number="`${content.vehicle.plate_number}`"
                        >
                            <img
                                src="~@qariin-fe/src/images/offers/car-plate.jpg"
                                class="w-full h-full object-contain"
                                alt="Car Plate"
                            />
                            <span v-if="content.vehicle.plate_letter1 && content.vehicle.plate_letter1.arabic_letter" class="offers__plate__arabic">{{
                                `${content.vehicle.plate_letter3?.arabic_letter} ${content.vehicle.plate_letter2?.arabic_letter} ${content.vehicle.plate_letter1?.arabic_letter}`
                            }}</span>
                            <span v-if="content.vehicle.plate_letter1" class="offers__plate__english">{{
                                `${content.vehicle.plate_letter1.english_letter} ${content.vehicle.plate_letter2.english_letter} ${content.vehicle.plate_letter3.english_letter}`
                            }}</span>
                        </figure>
                    </article>
                </div>
            </div>
        </section>

        <section class="offers__list">
            <div class="container" :class="{'dimmed': filtersAreLoading}">
                <div class="flex items-center justify-start flex-wrap">
                    <h2 class="text-xl lg:text-2xl text-black">العروض</h2>
                    <div class="mt-5 mt-2 ms-5">
                        <button
                        :class="'rounded-tr-lg rounded-br-lg px-5 ' + (!showComprehensiveCompanies ? 'bg-primary text-white' : 'bg-white text-primary' ) "
                        @click="showComprehensiveCompanies = false"
                        >
                            <span class="whitespace-nowrap">ضد الغير</span>
                        </button>
                        <button
                            :class="'rounded-tl-lg rounded-bl-lg px-5 ' + (showComprehensiveCompanies ? 'bg-primary text-white' : 'bg-white text-primary' ) "
                            @click="showComprehensiveCompanies = true"
                        >
                            <span class="whitespace-nowrap">الشامل</span>
                        </button>
                    </div>
                    <form ref="repairAgency" method="POST" :action="route('quotations.apply.other.infos')">
                        <input type="hidden" name="search_id" :value="searchId" />
                        <input type="hidden" name="vehicle_id" :value="vehicleId" />
                        <input type="hidden" name="vehicle_agency_repair" :value="repairAgency" />
                        <input type="hidden" name="_token" :value="csrf" />
                    </form>
                    <div class="flex-grow flex items-center justify-end max-w-screen overflow-x-scroll mt-8">
                        <template v-if="showComprehensiveCompanies">
                            <fieldset class="me-2">
                                <input type="radio" name="order-repair" id="order-repair-workshops" value="0" v-model="repairAgency" @change="selectRepairAgency()" />
                                <label for="order-repair-workshops">
                                    الإصلاح في الورش
                                </label>
                            </fieldset>
                            <fieldset class="me-4">
                                <input type="radio" name="order-repair" id="order-repair-agency" value="1" v-model="repairAgency" @change="selectRepairAgency()" />
                                <label for="order-repair-agency">
                                    الإصلاح في الوكاله
                                </label>
                            </fieldset>
                        </template>
                        <button
                            class="offers__companies"
                            @click="$refs.filterCompanies.toggleModal()"
                        >
                            <span class="whitespace-nowrap">فلتر شركات التامين</span>
                        </button>
                        <fieldset
                            class="selectbox min mb-2"
                            :style="`--icon: url('${
                                require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                    .default
                            }');`"
                        >
                            <select
                                name="order-offers"
                                id="order-offers"
                                v-model="order"
                                @change="sendOrders"
                            >
                                <option value="null" selected disabled>
                                    رتب حسب السعر
                                </option>
                                <option value="desc">رتب السعر-تنازلي</option>
                                <option value="asc">رتب السعر - تصاعدي</option>
                            </select>
                        </fieldset>
                    </div>
                </div>
                <template
                    v-if="!showComprehensiveCompanies"
                >
                    <template v-for="product in filteredTPLOffers" :key="product.id">
                        <Offer
                            :offer="product"
                            :comprehensive-offers="product.comprehensiveOffers"
                            :csrf="csrf"
                            :company="product.company"
                            :search-id="searchId"
                            :company-id="product.company.id"
                            :vehicle-value="content.vehicle.vehicle_value"
                        />
                    </template>
                </template>
                <template
                    v-else
                >
                    <template v-for="product in filteredComprehensiveOffers" :key="product.id">
                        <Offer
                            :offer="product"
                            comprehensive
                            :comprehensive-offers="[]"
                            :comprehensive-quotation-id="product && product.id"
                            :csrf="csrf"
                            :company="product.company"
                            :search-id="searchId"
                            :company-id="product.company.id"
                            :vehicle-value="content.vehicle.vehicle_value"
                        />
                    </template>
                </template>
            </div>
        </section>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
import Offer from "@/components/Offer.vue";
import Modal from "@/components/Modal.vue";
import AdditionalInfoModal from "@/components/AdditionalInfoModal.vue";
import AddDriversModal from "@/components/AddDriversModal.vue";
import NCDTableModal from "@/components/NCDTableModal.vue";
import ErrorsAlert from "@/components/ErrorsAlert.vue";

export default {
    components: {
        AppLayout,
        Head,
        Offer,
        Modal,
        AdditionalInfoModal,
        AddDriversModal,
        NCDTableModal,
        ErrorsAlert,
    },
    props: {
        csrf: {
            type: String,
            required: true,
        },
        currentHijriYear: {
            type: String,
            required: true,
        },
        // eslint-disable-next-line vue/prop-name-casing
        general_errors: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            order: null, // asc or desc
            repairAgency: 0, // asc or desc
            searchId: null,
            blurEnabled: false,
            filtersAreLoading: false,
            additionalInfoApplied: false,
            additionalInfo: {},
            additionalDriversDataApplied: false,
            additionalDriversData: [],
            content: {},
            other_info: {},
            selectedCompanies: [],
            filteredTPLOffers: [],
            filteredComprehensiveOffers: [],
            driversCleared: false,
            add_drivers: false,
            additional_info: false,
            showComprehensiveCompanies: false,
            deletedDrivers: [],
        };
    },
    computed: {
        companies() {
            return this.content.companies.filter(company => company.quotations.length);
        },
        activeCompanies() {
            if (!this.showComprehensiveCompanies)
                return this.content.companies;
            else
                return this.content.companies.filter(company => {
                    return !!company.has_comprehensive;
                });
        },
        vehicleId() {
            return this.content.vehicle.id;
        },
        hasCompanies() {
            return !!this.selectedCompanies.length;
        },
        hasNcdApplied() {
          const itContains = this.content.ncd_result.find(ncd => ncd.applied);

          return !!itContains;
        },
        errorMessages() {
            if (!this.general_errors) return;

            var errors = [];
            Object.keys(this.general_errors).forEach((key) => {
                errors.push(this.general_errors[key][0]);
            });

            return errors;
        },
        companiesLengthInSearchParams() {
            const params = new URLSearchParams(window.location.search);

            if (!params.has("companies")) return 0;

            const companies = params.get("companies").split(',');

            return companies.length;
        },
        tplOffers() {
            const companies = this.content.companies;
            const tplOffers = [];
            companies.forEach(company => {
                if (!company.quotations.length > 0) return;
                const offer = this.getTplProduct(company.quotations);
                if (!offer || !offer.id) return;
                offer.comprehensiveOffers = this.getComprehensiveProducts(company.quotations);
                offer.company = company;
                tplOffers.push(offer);
            })

            return tplOffers;
        },
        comprehensiveOffers() {
            const companies = this.content.companies;
            const comprehensiveOffers = [];

            companies.forEach(company => {
                if (!company.quotations.length > 0) return;
                const offer = this.getComprehensiveProduct(company.quotations);
                offer.company = company;
                comprehensiveOffers.push(offer);
            })
            return comprehensiveOffers;
        },
    },
    watch: {
        showComprehensiveCompanies() {
            const url = new URL(window.location);
            const params = new URLSearchParams(window.location.search);

            if (params.has("type")) {
                url.searchParams.delete("type");
                url.searchParams.append("type", this.showComprehensiveCompanies ? 'comprehensive' : 'tpl');
            } else {
                url.searchParams.append("type", this.showComprehensiveCompanies ? 'comprehensive' : 'tpl');
            }

            history.pushState({}, null, url.href)
            this.setFilteredOffers();
        },
        filtersAreLoading() {
            if (!this.blurEnabled) return;

            this.filtersAreLoading = true;
        }
    },
    methods: {
        getImgUrl(path){
            try{
                if (!path) throw new Error();
                return path
            }catch(_){
                return require('@qariin-fe/src/images/default-car-logo.png').default
            }
        },
        filterCompanies(company) {
            const id = company.id.toString();
            if (this.selectedCompanies.includes(id)) {
                this.selectedCompanies.splice(
                    this.selectedCompanies.indexOf(id),
                    1
                );
            } else {
                this.selectedCompanies.push(id);
            }
        },
        sendFilters(valid) {
            this.blurEnabled = true;
            this.filtersAreLoading = true;
            if (!valid) return;
            const url = new URL(window.location);
            const params = new URLSearchParams(window.location.search);

            var companies = this.selectedCompanies.join(',');

            if (params.has("companies")) {
                url.searchParams.delete("companies");
                url.searchParams.append("companies", companies);
            } else {
                url.searchParams.append("companies", companies);
            }

            window.location = url.href;
        },
        resetFilters() {
            const url = new URL(window.location);
            const params = new URLSearchParams(window.location.search);

            if (params.has("companies")) {
                url.searchParams.delete("companies");
            }

            if (params.has("order")) {
                url.searchParams.delete("order");
            }

            window.location = url.href;
        },
        async sendOrders() {
            this.blurEnabled = true;
            this.filtersAreLoading = true;
            const url = new URL(window.location);
            const params = new URLSearchParams(window.location.search);
            var order = this.order;

            if (params.has("order")) {
                url.searchParams.delete("order");
                url.searchParams.append("order", order);
                url.searchParams.append("type", this.showComprehensiveCompanies ? 'comprehensive' : 'tpl');
            } else {
                url.searchParams.append("order", order);
                url.searchParams.append("type", this.showComprehensiveCompanies ? 'comprehensive' : 'tpl');
            }

            window.location = url.href;

        },
        openAdditionalData() {
            this.additional_info = true;
            setTimeout(() => {
                this.$refs.additional_info.toggleModal();
            }, 100)
        },
        openAddDrivers() {
            this.add_drivers = true;
            setTimeout(() => {
                this.$refs.add_drivers.toggleModal();
            }, 100)
        },
        setFilteredOffers() {
            const params = new URLSearchParams(window.location.search);
            const value = params.has("type") ? params.get("type") : false;
            if (value == 'comprehensive')
                this.showComprehensiveCompanies = true;
            else if (value == 'tpl')
                this.showComprehensiveCompanies = false;

            if (!this.showComprehensiveCompanies) {
                if (this.order == 'desc') {
                    this.filteredTPLOffers = [...this.tplOffers.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))];
                } else if (this.order == 'asc') {
                    this.filteredTPLOffers = [...this.tplOffers.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))].reverse();
                } else {
                    this.filteredTPLOffers = [...this.tplOffers]
                }
            } else {
                if (this.order == 'desc') {
                    this.filteredComprehensiveOffers = [...this.comprehensiveOffers.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))];
                } else if (this.order == 'asc') {
                    this.filteredComprehensiveOffers = [...this.comprehensiveOffers.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))].reverse();
                } else {
                    this.filteredComprehensiveOffers = [...this.comprehensiveOffers];
                }
            }
        },
        selectRepairAgency() {
            this.blurEnabled = true;
            this.filtersAreLoading = true;
            setTimeout(() => {
                this.$refs.repairAgency.submit();
            }, 200);  
        },
        getTplProduct(quotations) {
            if (!quotations.length) return [];
            return quotations.find(quotation => !!quotation.search_id && quotation.product_type == "TPL")?.products[0]
        },
        getComprehensiveProduct(quotations) {
            if (!quotations.length) return [];
            let mainProduct = {};

            let products = quotations.find(quotation => !!quotation.search_id && quotation.product_type == "Comprehensive")?.products;
            products = products || [];
            products.forEach(product => {
                product.selectedAdditionalCovers = [];
                product.selectedAdditionalCoversAmount = 0;
            })

            if (products.length > 1) {
                mainProduct = products[0];
                mainProduct.deductible_offers = [];
                products.forEach(product => {
                    mainProduct.deductible_offers.push(product);
                })
            } else if (products.length == 1) {
                mainProduct = products[0]
            }
            return mainProduct;
        },
        getComprehensiveProducts(quotations) {
            if (!quotations.length) return [];
            let mainProduct = {};

            let products = quotations.find(quotation => !!quotation.search_id && quotation.product_type == "Comprehensive")?.products;
            products = products || [];
            products.forEach(product => {
                product.selectedAdditionalCovers = [];
                product.selectedAdditionalCoversAmount = 0;
            })

            if (products.length > 1) {
                mainProduct = products[0];
                mainProduct.deductible_offers = [];
                products.forEach(product => {
                    mainProduct.deductible_offers.push(product);
                })
            } else if (products.length == 1) {
                mainProduct = products[0]
            }
            return products;
        },
        async getOffers(order, companies) {
            const params = new URLSearchParams(window.location.search); // query params
            var url = null;

            if (params.has("id")) {
                const id = params.get("id");
                this.searchId = id;
                url = new URL(route("quotations.index", id));
            } else {
                window.location = route(`home`);
            }

            if (params.has("order") || order) {
                const value = params.has("order") ? params.get("order") : order;
                url.searchParams.append("order", value);
                this.order = value;
            }

            if (params.has("vehicle_agency_repair")) {
                const value = Number(params.get("vehicle_agency_repair"));
                this.showComprehensiveCompanies = true;
                this.repairAgency = value;
            }

            if (params.has("companies") || companies) {
                const value = params.has("companies")
                    ? params.get("companies")
                    : companies;
                url.searchParams.append("companies", value);
                this.selectedCompanies = value.split(",");
            }

            await fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    this.content = data.data;
                    if (!params.has("vehicle_agency_repair"))
                        this.repairAgency = (data.data.search && data.data.search.vehicle_agency_repair) || 0;
                });
        },
        saveAdditionalData(data) {
            this.additionalInfoApplied = true;
            this.additionalInfo = data;
        },
        saveAdditionalDriversData(data) {
            this.additionalDriversDataApplied = true;
            if (data.driversCleared) {
                this.driversCleared = true;
                this.deletedDrivers = data.deletedDrivers;
            }
            this.additionalDriversData = data;
        },
    },
    async mounted() {
        await this.getOffers();
        this.setFilteredOffers();

        this.other_info = this.content.vehicle.other_info;
    },
};
</script>
