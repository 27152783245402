<template>
    <app-layout>
        <Head>
            <title>Additional information | Qariin</title>
        </Head>
        <div class="otherInfo">
            <h1 class="text-xl lg:text-3xl text-black text-center mb-6">
                معلومات اضافية
            </h1>
            <div class="otherInfo__addons">
                <form
                    ref="form"
                    :action="route('quotations.apply.other.infos')"
                    method="post"
                    class="w-full"
                >
                <!-- <fieldset
                    class="input input-w-auto"
                    :style="`--icon: url('${
                        require('@qariin-fe/src/images/input-icons/car.svg')
                            .default
                    }');`"
                >
                    <label for="vehicle-value">قيمة المركبة</label>
                    <input
                        v-model="vehicleValue"
                        type="number"
                        id="vehicle-value"
                        required
                        min="10000"
                        name="vehicle-value"
                        placeholder="اكتب قيمة المركبة هنا..."
                    />
                </fieldset> -->
                <button
                type="button"
                    class="otherInfo__addons__button"
                    @click="openAdditionalData"
                >
                    <span>هل ترغب بإضافة معلومات اخرى</span>
                    <span class="icon">+</span>
                </button>
                <button
                type="button"
                    class="otherInfo__addons__button"
                    @click="openAddDrivers"
                >
                    <span>هل ترغب بإضافة سائق؟</span>
                    <span class="icon">+</span>
                </button>
                <!-- <button
                    class="otherInfo__addons__button"
                    @click="$refs.promotional.toggleModal"
                >
                    <span>البرامج الترويجية</span>
                    <span class="icon">+</span>
                </button> -->
                    <template
                        v-if="
                            additionalInfoApplied ||
                            additionalDriversDataApplied ||
                            driversCleared
                        "
                    >
                        <template v-if="additionalInfoApplied">
                            <template
                                v-for="(key, index) in Object.keys(
                                    additionalInfo
                                )"
                                :key="index"
                            >
                                <template
                                    v-if="key == 'another_country_licenses'"
                                >
                                    <template
                                        v-for="(license, i) in additionalInfo[
                                            key
                                        ]"
                                        :key="i"
                                    >
                                        <input
                                            type="hidden"
                                            :name="`otherInfo[another_country_licenses][${i}][country_code]`"
                                            :value="license.country_code"
                                        />
                                        <input
                                            type="hidden"
                                            :name="`otherInfo[another_country_licenses][${i}][years]`"
                                            :value="license.years"
                                        />
                                    </template>
                                </template>
                                <template v-else-if="key == 'violations'">
                                    <template
                                        v-for="(violation, i) in additionalInfo[
                                            key
                                        ]"
                                        :key="i"
                                    >
                                        <input
                                            type="hidden"
                                            :name="`otherInfo[violations][${i}]`"
                                            :value="violation"
                                        />
                                    </template>
                                </template>
                                <input
                                    v-else-if="key == 'have_other_info'"
                                    type="hidden"
                                    :name="key"
                                    :value="additionalInfo[key]"
                                />
                                <input
                                    v-else
                                    type="hidden"
                                    :name="`otherInfo[${key}]`"
                                    :value="additionalInfo[key]"
                                />
                            </template>
                        </template>
                        <template
                            v-if="
                                !driversCleared && additionalDriversData.length
                            "
                        >
                            <template
                                v-for="(driver, k) in additionalDriversData"
                                :key="k"
                            >
                                <template
                                    v-for="(key, index) in Object.keys(driver)"
                                    :key="index"
                                >
                                    <template
                                        v-if="key == 'another_country_licenses'"
                                    >
                                        <template
                                            v-for="(license, i) in driver[key]"
                                            :key="i"
                                        >
                                            <input
                                                type="hidden"
                                                :name="`drivers[${k}][another_country_licenses][${i}][country_code]`"
                                                :value="license.country_code"
                                            />
                                            <input
                                                type="hidden"
                                                :name="`drivers[${k}][another_country_licenses][${i}][years]`"
                                                :value="license.years"
                                            />
                                        </template>
                                    </template>
                                    <template v-else-if="key == 'violations'">
                                        <template
                                            v-for="(violation, i) in driver[
                                                key
                                            ]"
                                            :key="i"
                                        >
                                            <input
                                                type="hidden"
                                                :name="`drivers[${k}][violations][${i}]`"
                                                :value="violation"
                                            />
                                        </template>
                                    </template>
                                    <input
                                        v-else
                                        type="hidden"
                                        :name="`drivers[${k}][${key}]`"
                                        :value="driver[key]"
                                    />
                                </template>
                            </template>
                        </template>
                        <!-- <template v-if="driversCleared">
                            <input v-for="(driver, index) in deletedDrivers" :key="driver.id" type="hidden" :name="`deleted_drivers[${index}]`" :value="driver">
                        </template> -->
                    </template>
                    <input type="hidden" name="search_id" :value="searchId" />
                    <input type="hidden" name="vehicle_id" :value="vehicleId" />
                    <input
                        type="hidden"
                        v-if="hasPromotion"
                        name="coupon_id"
                        :value="promotion"
                    />
                    <input
                        type="hidden"
                        v-if="hasPromotionOffer"
                        name="promotion_offer_user_id"
                        :value="promotionOfferId"
                    />
                    <input type="hidden" name="_token" :value="csrf" />
                    <input
                        type="hidden"
                        name="pageUrl"
                        :value="`${route(
                            'other-info'
                        )}?id=${searchId}&vehicle=${vehicleId}`"
                    />
                    <div
                        v-show="!isLoading"
                        class="flex items-center justify-center mt-3"
                    >
                        <button
                            type="submit"
                            class="w-full me-3 bg-primary text-white rounded-md p-2 px-sm"
                            @click.prevent="submit"
                        >
                            <span> التالي </span>
                        </button>
                        <button
                            type="submit"
                            class="w-full bg-primary text-white rounded-md p-2 px-sm opacity-50"
                            @click.prevent="goBack"
                        >
                            <span> السابق </span>
                        </button>
                    </div>
                    <div v-show="isLoading" class="flex items-center justify-center my-1">
                        <VueLottiePlayer
                            style="width: 200px; height: 100px"
                            name="Qariin loader"
                            loop
                            path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
                        />
                    </div>
                </form>
            </div>
        </div>
        <ErrorsAlert
            v-if="errorMessages && errorMessages.length"
            :errors="errorMessages"
        />
        <AdditionalInfoModal
            v-if="additional_info"
            ref="additional_info"
            @saved="saveAdditionalData($event)"
        />
        <AddDriversModal
            v-if="add_drivers"
            ref="add_drivers"
            :added-drivers="vehicle?.drivers"
            :current-hijri-year="currentHijriYear"
            @saved="saveAdditionalDriversData($event)"
        />
        <PromotionalModal
            ref="promotional"
            @saved="savePromotionalData($event)"
        />
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
import AdditionalInfoModal from "@/components/AdditionalInfoModal.vue";
import PromotionalModal from "@/components/PromotionalModal.vue";
import AddDriversModal from "@/components/AddDriversModal.vue";
import ErrorsAlert from "@/components/ErrorsAlert.vue";
import VueLottiePlayer from "vue-lottie-player";

export default {
    components: {
        AppLayout,
        Head,
        AdditionalInfoModal,
        AddDriversModal,
        PromotionalModal,
        ErrorsAlert,
        VueLottiePlayer,
    },
    props: {
        csrf: {
            type: String,
            required: true,
        },
        currentHijriYear: {
            type: String,
            required: true,
        },
        // eslint-disable-next-line vue/prop-name-casing
        general_errors: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            additionalInfo: null,
            additionalInfoApplied: false,
            additionalDriversDataApplied: false,
            driversCleared: false,
            deletedDrivers: [],
            vehicleValue:null,
            additionalDriversData: [],
            searchId: null,
            vehicleId: null,
            vehicle: null,
            isLoading: false,
            add_drivers: false,
            additional_info: false,
            hasPromotion: false,
            hasPromotionOffer: false,
            promotionOfferId: null,
            promotion: null,
        };
    },
    computed: {
        errorMessages() {
            if (!this.general_errors) return;

            var errors = [];
            Object.keys(this.general_errors).forEach((key) => {
                errors.push(this.general_errors[key][0]);
            });

            return errors;
        },
    },
    methods: {
        saveAdditionalData(data) {
            this.additionalInfoApplied = true;
            this.additionalInfo = data;
        },
        saveAdditionalDriversData(data) {
            this.additionalDriversDataApplied = true;
            if (data.driversCleared) {
                this.driversCleared = true;
                this.deletedDrivers = data.deletedDrivers;
            }
            this.additionalDriversData = data;
        },
        savePromotionalData(data) {
            if (data.code) {
                this.hasPromotion = data.code;
                this.promotion = data.id;
            } else if (data.promotionOffer) {
                this.hasPromotionOffer = data.promotionOffer;
                this.promotionOfferId = data.id;
            }
        },
        openAdditionalData() {
            this.additional_info = true;
            setTimeout(() => {
                this.$refs.additional_info.toggleModal();
            }, 100)
        },
        openAddDrivers() {
            this.add_drivers = true;
            setTimeout(() => {
                this.$refs.add_drivers.toggleModal();
            }, 100)
        },
        submit() {
            this.isLoading = true;
            this.$refs.form.submit();
        },
        cancel() {
            this.additionalInfoApplied = false;
            this.additionalDriversDataApplied = false;
            this.driversCleared = false;
            this.additionalInfo = null;
            this.additionalDriversData = null;
            this.$refs.form.submit();
        },
        goBack(){
            history.back();
        },
        async getVehicleInfo(vehicleId) {
            await fetch("/api/vehicle/" + vehicleId)
                .then((res) => res.json())
                .then((data) => {
                    this.vehicle = data.data;
                });
        },
    },
    mounted() {
        const params = new URLSearchParams(window.location.search); // query params
        if (params.has("id") && params.has("vehicle")) {
            this.searchId = params.get("id");
            this.vehicleId = params.get("vehicle");
            this.getVehicleInfo(this.vehicleId);
        } else {
            window.location = route(`home`);
        }
    },
};
</script>
