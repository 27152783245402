<template>
    <app-layout title="Profile">
        <Head>
            <title>Profile | Qariin</title>
        </Head>
        <div class="grid grid-cols-1 md:grid-cols-6 lg:grid-cols-5 relative place-items-start">
            <div
                class="md:col-span-2 lg:col-span-1 py-2 md:py-6 px-xs sticky z-30 top-16 md:top-20 md:top-16 right-0 shadow-md w-full md:h-screen bg-white">
                <ul class="profileMenu w-full flex md:flex-col items-start justify-start max-w-full overflow-x-scroll">
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{ 'border-b-secondary-900': routes.home }">
                        <a href="#home"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold': routes.home,
                            }" @click="toggleTarget('home')">
                            <img v-if="routes.home" :src="
                                    require('@qariin-fe/src/images/profile/profile-active.svg')
                                        .default
                                " class="me-3 w-6" alt="Profile" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/profile.svg')
                                        .default
                                " class="me-3 w-6" alt="Profile" />
                            <span>الملف الشخصي</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{ 'border-b-secondary-900': routes.vehicles }">
                        <a href="#vehicles"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold': routes.vehicles,
                            }" @click="toggleTarget('vehicles')">
                            <img v-if="routes.vehicles" :src="
                                    require('@qariin-fe/src/images/profile/car-active.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="vehicles" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/car.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="vehicles" />
                            <span>المركبات</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{ 'border-b-secondary-900': routes.address }">
                        <a href="#address"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold': routes.address,
                            }" @click="toggleTarget('address')">
                            <img v-if="routes.address" :src="
                                    require('@qariin-fe/src/images/profile/address-active.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="National Address" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/address.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="National Address" />
                            <span>العنوان الوطني</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{ 'border-b-secondary-900': routes.orders }">
                        <a href="#orders"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold': routes.orders,
                            }" @click="toggleTarget('orders')">
                            <img v-if="routes.orders" :src="
                                    require('@qariin-fe/src/images/profile/orders-active.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="Orders" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/orders.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="Orders" />
                            <span>طلبات الشراء</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{ 'border-b-secondary-900': routes.support }">
                        <a href="#support"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold': routes.support,
                            }" @click="toggleTarget('support')">
                            <img v-if="routes.support" :src="
                                    require('@qariin-fe/src/images/profile/support-active.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="Support" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/support.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="Support" />
                            <span>الدعم الفني</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{
                            'border-b-secondary-900': routes.notifications,
                        }">
                        <a href="#notifications"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold':
                                    routes.notifications,
                            }" @click="toggleTarget('notifications')">
                            <img v-if="routes.notifications" :src="
                                    require('@qariin-fe/src/images/profile/notifications-active.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="notifications" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/notifications.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="notifications" />
                            <span>التنبيهات</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        :class="{ 'border-b-secondary-900': routes.settings }">
                        <a href="#settings"
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap" :class="{
                                'text-secondary-900 font-bold': routes.settings,
                            }" @click="toggleTarget('settings')">
                            <img v-if="routes.settings" :src="
                                    require('@qariin-fe/src/images/profile/settings-active.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="Settings" />
                            <img v-else :src="
                                    require('@qariin-fe/src/images/profile/settings.svg')
                                        .default
                                " class="text-secondary me-3 w-6" alt="Settings" />
                            <span>الإعدادات</span>
                        </a>
                    </li>
                    <li class="w-full mx-xs mb-2 me-10 md:me-0 md:border-b flex items-center justify-start cursor-pointer"
                        @click="logout">
                        <a @click.prevent
                            class="text-secondary w-full flex items-center justify-start py-4 whitespace-nowrap">
                            <img :src="
                                    require('@qariin-fe/src/images/profile/logout.svg')
                                        .default
                                " class="me-3 w-6" alt="Profile" />
                            <span>تسجيل خروج</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="w-full mx-auto py-10 bg-secondary-50 px-xs sm:px-6 lg:px-8 col-span-4 min-h-screen">
                <!-- Profile Home -->
                <div v-if="routes.home">
                    <div>
                        <h2 class="text-xl text-black">بيانات الحساب</h2>

                        <div class="bg-white rounded-md p-xs shadow-md grid grid-cols-1 md:grid-cols-3 my-4">
                            <div>
                                <span class="text-xs text-tertiary">
                                    البريد الالكتروني
                                </span>
                                <p class="text-black mt-1">
                                    {{ $page.props.user.email }}
                                </p>
                            </div>
                            <div>
                                <span class="text-xs text-tertiary">
                                    رقم الجوال
                                </span>
                                <p class="text-black mt-1">
                                    {{ $page.props.user.mobile }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="my-8">
                        <div class="flex items-center justify-between">
                            <h2 class="text-black text-lg">الوثائق</h2>

                            <a :href="route('home')" data-modal="add-driver" class="offers__addons__button">
                                <span class="icon">+</span>
                                <span> اضف وثيقة جديدة </span>
                            </a>
                        </div>
                        <p v-if="
                                !policies.length ||
                                !policies[0] ||
                                !policies[0].id
                            " class="text-center py-3">
                            لا توجد وثائق حالياً
                        </p>
                        <article v-else v-for="policy in policies" :key="policy.id"
                            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-xs rounded-md shadow-md bg-white my-3">
                            <div class="md:col-span-2 lg:col-span-3 pb-3 mb-3 border-b">
                                <figure class="w-44 h-12">
                                    <img v-if="policy.company.avatar" :src="
                                        `/storage/${policy.company.avatar}`
                                            // require(`@storage/${policy.company.avatar}`)
                                            //     .default
                                        " :alt="policy.company.name_en" class="max-w-full max-h-full object-contain" />
                                </figure>
                            </div>
                            <div class="flex flex-col items-start justify-start col-span-2 md:col-span-1">
                                <span class="text-secondary mb-1 mt-4 text-xs">رقم الوثيقة</span>
                                <span class="text-secondary-900">{{
                                    policy.policy_number
                                    }}</span>
                                <span class="text-secondary mb-1 mt-4 text-xs">تاريخ بداية التأمين</span>
                                <span class="text-secondary-900" style="direction: ltr">{{
                                    formateDate(
                                    policy.policy_effective_date
                                    )
                                    }}</span>
                                <span class="text-secondary mb-1 mt-4 text-xs">تاريخ إنتهاء الوثيقة</span>
                                <span class="text-secondary-900" style="direction: ltr">{{
                                    formateDate(policy.policy_expiry_date)
                                    }}</span>
                            </div>
                            <div class="flex flex-col items-start justify-start col-span-2 md:col-span-1">
                                <span class="text-secondary mb-1 mt-4 text-xs">وصف المركبة</span>
                                <span class="text-secondary-900">
                                    {{ policy.vehicle.maker?.name }}
                                    {{ policy.vehicle.vehicle_model?.name }}
                                    {{ policy.vehicle.model_year }}
                                    {{ policy.vehicle.major_color }}
                                </span>
                                <span class="text-secondary mb-1 mt-4 text-xs">حالة الوثيقة</span>
                                <span class="text-secondary-900">{{
                                    Number(policy.status) ? "مفعل" : "غير مفعل"
                                    }}</span>
                                <article v-if="policy.vehicle && policy.vehicle.plate_number" class="mt-5">
                                    <figure class="profile__plate"
                                        :data-plate-number="`${policy.vehicle.plate_number}`">
                                        <img src="~@qariin-fe/src/images/offers/car-plate.jpg"
                                            class="w-full h-full object-contain" alt="Car Plate" />
                                        <template v-if="
                                                policy.vehicle.plate_letter1 &&
                                                policy.vehicle.plate_letter2 &&
                                                policy.vehicle.plate_letter3
                                            ">
                                            <span v-if="policy.vehicle.plate_letter1.arabic_letter"
                                                class="profile__plate__arabic">{{
                                                `${policy.vehicle.plate_letter3?.arabic_letter}
                                                ${policy.vehicle.plate_letter2?.arabic_letter}
                                                ${policy.vehicle.plate_letter1?.arabic_letter}`
                                                }}</span>
                                            <span class="profile__plate__english">{{
                                                `${policy.vehicle.plate_letter1?.english_letter}
                                                ${policy.vehicle.plate_letter2?.english_letter}
                                                ${policy.vehicle.plate_letter3?.english_letter}`
                                                }}</span>
                                        </template>
                                    </figure>
                                </article>
                            </div>

                            <div class="col-span-2 lg:col-span-1">
                                <div class="o-progress-circle o-progress-circle--rounded mx-auto">
                                    <div class="o-progress-circle__number">
                                        <span data-pourcent>{{
                                            calculateDays(
                                            policy.policy_expiry_date
                                            )
                                            }}</span>
                                        <span class="text-xs text-secondary">على إنتهاء الوثيقة</span>
                                    </div>
                                    <div class="o-progress-circle__fill">
                                        <svg class="icon" viewBox="0 0 40 40">
                                            <circle r="15.9154943092" cy="20" cx="20" />
                                            <circle r="15.9154943092" cy="20" cx="20" :stroke-dashoffset="
                                                    100 -
                                                    getDaysPercentage(
                                                        policy.policy_expiry_date
                                                    )
                                                " transform="rotate(-90,20,20)" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="flex items-center justify-center">
                                    <a :href="policy.policy_file_url" class="flex items-center max-w-xs">
                                        <span class="text-sm text-secondary">
                                            طباعة الوثيقة
                                        </span>
                                        <img src="@qariin-fe/src/images/icons/print.svg" class="w-8 h-8"
                                            alt="Print Icon" />
                                    </a>
                                    <div class="w-px h-5 bg-secondary-200 ms-2 me-3"></div>
                                    <a href="/" class="flex items-center max-w-xs">
                                        <span class="text-sm text-secondary me-1">
                                            تجديد الوثيقة
                                        </span>
                                        <img src="@qariin-fe/src/images/icons/reload.svg" class="w-5 h-5"
                                            alt="Renew Icon" />
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>

                <!-- Vehicles -->
                <div v-if="routes.vehicles">
                    <h1 class="text-xl text-secondary-900 mb-6 pb-2 border-b">
                        المركبات
                    </h1>
                    <template v-if="vehicles.length">
                        <div v-for="vehicle in vehicles" :key="vehicle.id"
                            class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 p-xs bg-white rounded-md shadow-md my-3 max-w-3xl gap-y-5">
                            <div class="flex items-center justify-start">
                                <img :src="getImgUrl(vehicle.car_logo_path)" class="w-12" alt="Car Logo" />
                                <div class="ms-4">
                                    <h3 class="text-secondary text-xs">
                                        وصف المركبة
                                    </h3>
                                    <span class="text-black">
                                        {{ vehicle.maker?.name }}
                                        {{ vehicle.vehicle_model?.name }}
                                        {{ vehicle.model_year }}
                                        {{ vehicle.major_color }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <h3 class="text-secondary text-xs">
                                    الرقم التسلسلي/الجمركي
                                </h3>
                                <span class="text-black">
                                    {{ vehicle.number || vehicle.custom_number }}
                                </span>
                            </div>
                            <article v-if="vehicle.plate_number">
                                <figure class="profile__plate" :data-plate-number="`${vehicle.plate_number}`">
                                    <img src="~@qariin-fe/src/images/offers/car-plate.jpg"
                                        class="w-full h-full object-contain" alt="Car Plate" />
                                    <template v-if="
                                            vehicle.plate_letter1 &&
                                            vehicle.plate_letter2 &&
                                            vehicle.plate_letter3
                                        ">
                                        <span class="profile__plate__arabic">{{
                                            `${vehicle.plate_letter3?.arabic_letter}
                                            ${vehicle.plate_letter2?.arabic_letter}
                                            ${vehicle.plate_letter1?.arabic_letter}`
                                            }}</span>
                                        <span class="profile__plate__english">{{
                                            `${vehicle.plate_letter1?.english_letter}
                                            ${vehicle.plate_letter2?.english_letter}
                                            ${vehicle.plate_letter3?.english_letter}`
                                            }}</span>
                                    </template>
                                </figure>
                            </article>
                            <div class="md:col-span-3 lg:col-span-1 md:text-center">
                                <!-- <button @click="confirmVehicleDeletion(vehicle)">
                                    <span class="text-secondary pe-2">
                                        حذف المركبة
                                    </span>
                                    <img src="@qariin-fe/src/images/icons/delete.svg" class="w-6" alt="Delete Icon">
                                </button> -->
                            </div>
                        </div>
                    </template>
                    <p v-else>لا توجد معلومات لعرضها الان</p>
                </div>

                <!-- Addresses -->
                <div v-if="routes.address">
                    <h1 class="text-xl text-secondary-900 mb-6 pb-2 border-b">
                        العنوان الوطني
                    </h1>
                    <template v-if="identities.length">
                        <div v-for="identity in identities" :key="identity.id"
                            class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-3 p-xs bg-white rounded-md shadow-md my-3 max-w-3xl gap-y-5">
                            <div class="flex items-center justify-start col-span-2">
                                <img src="@qariin-fe/src/images/profile/default-profile.jpg"
                                    class="w-12 h-12 rounded-full" alt="Profile Pic" />
                                <div class="ms-4">
                                    <h3 class="text-secondary text-xs">
                                        الاسم بالكامل
                                    </h3>
                                    <span class="text-black">
                                        {{
                                        identity.name ||
                                        `${identity.first_name} ${identity.father_name} ${identity.grand_father_name}
                                        ${identity.family_name} `
                                        }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <h3 class="text-secondary text-xs">
                                    رقم الهوية
                                </h3>
                                <span class="text-black">
                                    {{ identity.number }}
                                </span>
                            </div>
                            <div>
                                <h3 class="text-secondary text-xs">المدينة</h3>
                                <span class="text-black">
                                    {{ identity.identity_addresses[0]?.city }}
                                </span>
                            </div>
                            <!-- <div class="md:col-span-4 lg:col-span-1 md:text-center">
                                <button>
                                    <span class="text-secondary pe-2">
                                        تحديث البيانات
                                    </span>
                                    <img src="@qariin-fe/src/images/input-icons/reload.svg" class="w-6" alt="Delete Icon">
                                </button>
                            </div> -->
                        </div>
                    </template>
                    <p v-else>لا توجد معلومات لعرضها الان</p>
                </div>

                <!-- Notifications -->
                <div v-if="routes.notifications">
                    <h1 class="text-xl text-secondary-900 mb-6 pb-2 border-b">
                        التنبيهات
                    </h1>
                    <template v-if="notifications.length">
                        <div v-for="notification in notifications" :key="notification.id"
                            class="relative p-xs pb-8 bg-white rounded-md shadow-md my-3 max-w-3xl my-5">
                            <h3 class="mb-2">{{ notification.title_ar }}</h3>
                            <p class="text-black text-sm">
                                {{ notification.body_ar }}
                            </p>
                            <span class="absolute bottom-2 left-2 text-xs text-secondary" style="direction: ltr">{{
                                formateDate(notification.created_at)
                                }}</span>
                        </div>
                    </template>
                    <p v-else>لا توجد معلومات لعرضها الان</p>
                </div>

                <!-- Orders -->
                <Orders v-if="routes.orders" :completed-orders="completedOrders"
                    :not-completed-orders="notCompletedOrders" :csrf="csrf" />

                <!-- support -->
                <div v-if="routes.support">
                    <div class="flex flex-col md:flex-row items-start md:items-center justify-between pb-3 border-b">
                        <h1 class="text-xl text-secondary-900 mb-6 pb-2">
                            التذاكر السابقة
                        </h1>
                        <div class="flex items-center justify-center">
                            <a href="/faqs" data-modal="got-to-faqs" class="offers__addons__button">
                                <span> الاسئلة الشائعة </span>
                            </a>
                            <a :href="route('home')" data-modal="got-to-faqs" class="offers__addons__button"
                                @click.prevent="
                                    $refs.newSupportTicket.toggleModal()
                                ">
                                <span class="icon">+</span>
                                <span> فتح تذكرة جديدة </span>
                            </a>
                            <a :href="route('home')" data-modal="got-to-faqs" class="offers__addons__button"
                                @click.prevent="
                                    $refs.compalintsModal.toggleModal()
                                ">
                                <!-- <span class="icon">+</span> -->
                                <span>عرض آلية الشكوى</span>
                            </a>
                        </div>
                    </div>
                    <template v-if="tickets.length">
                        <div v-for="(ticket, index) in tickets" :key="ticket.id"
                            class="grid grid-cols-5 gap-2 p-xs bg-white shadow-md rounded-md my-5 text-sm">
                            <div class="grid grid-cols-5 gap-3 col-span-5 pb-4 border-b">
                                <span class="text-secondary">رقم التذكرة</span>
                                <span class="block col-span-3 text-black">{{
                                    ticket.id
                                    }}</span>
                                <div class="col-span-5 md:col-span-1">
                                    <span class="text-black pe-2">الحالة :</span>
                                    <span v-if="ticket.status == 'active'" class="text-success">نشطة</span>
                                    <span v-if="ticket.status == 'pending'" class="text-warning">جاري التحقيق</span>
                                    <span v-if="ticket.status == 'closed'" class="text-secondary">مغلق</span>
                                </div>
                            </div>
                            <span class="text-secondary">نوع التذكرة</span>
                            <p class="col-span-3 text-black">
                                {{ ticket.reason }}
                            </p>
                            <span class="text-secondary text-xs text-end" style="direction: ltr">{{
                                formateDate(ticket.created_at) }}</span>
                            <template v-if="ticket.vehicle && ticket.vehicle.id">
                                <span class="text-secondary">وصف المركبة</span>
                                <p class="col-span-4 text-black">
                                    {{ ticket.vehicle.maker?.name }}
                                    {{ ticket.vehicle.vehicle_model?.name }}
                                    {{ ticket.vehicle.model_year }}
                                    {{ ticket.vehicle.major_color }}
                                </p>
                            </template>
                            <template v-if="!!ticket.attachment && ticket.attachment != 'null'">
                                <span>المرفقات</span>
                                <a target="__blank" :href="getAttachment(ticket.attachment)"
                                    class="col-span-4 text-primary">الملف المرفق</a>
                            </template>
                            <span class="text-secondary">الرسالة</span>
                            <p class="col-span-5 md:col-span-3 text-black">
                                {{ ticket.message }}
                            </p>
                            <div v-if="ticket.status != 'closed'">
                                <button class="offers__addons__button" @click.prevent="openMessages(index)">
                                    <span class="pe-2">اضافة رد</span>
                                    <img src="@qariin-fe/src/images/icons/chat.svg" alt="Chat Icon" class="w-6" />
                                </button>
                            </div>
                        </div>
                    </template>
                    <p v-else class="mt-3 text-secondary-900">لا توجد معلومات لعرضها الان</p>
                </div>

                <Modal ref="newSupportTicket" title="تذكرة جديدة">
                    <form ref="addTicket" :action="route('tickets.store')" method="post"
                        class="flex flex-col items-center justify-center">
                        <input type="hidden" name="_token" :value="csrf" />
                        <fieldset class="selectbox" :style="`--icon: url('${
                                require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                    .default
                            }');`">
                            <label for="ticket-category">تصنيف التذكرة</label>
                            <select v-model="input.ticketCategory" name="type" id="ticket-category">
                                <option value="null" disabled>اختر...</option>
                                <option v-for="category in categories" :key="category.code" :value="category.code">
                                    {{ category.name }}
                                </option>
                            </select>
                        </fieldset>
                        <fieldset class="selectbox" :style="`--icon: url('${
                                require('@qariin-fe/src/images/svg/arrow-down-secondary.svg')
                                    .default
                            }');`">
                            <label for="ticket-type">نوع التذكرة</label>
                            <select v-model="input.ticketType" name="reason" id="ticket-type">
                                <option value="null" disabled>اختر...</option>
                                <option v-for="type in ticketTypes" :key="type" :value="type">
                                    {{ type }}
                                </option>
                            </select>
                        </fieldset>
                        <fieldset v-if="input.ticketType != 'اخرى'" class="selectbox" :style="`--icon: url('${require('@qariin-fe/src/images/svg/arrow-down-secondary.svg').default}');`">
                            <label for="vehicle">المركبات</label>
                            <select v-model="input.vehicle" name="vehicle_id" id="vehicle">
                                <option value="null" disabled>اختر...</option>
                                <option v-for="vehicle in vehicles" :key="vehicle.id" :value="vehicle.id">
                                    {{ vehicle.maker?.name }}
                                    {{ vehicle.vehicle_model?.name }}
                                    {{ vehicle.model_year }}
                                    {{ vehicle.major_color }}
                                </option>
                            </select>
                        </fieldset>
                        <fieldset v-if="input.ticketCategory && input.ticketType"
                            class="input input-w-auto bg-white mt-5">
                            <textarea v-model="input.message" name="message" class="w-full h-32 resize-none"
                                placeholder="رسالتك هنا" required></textarea>
                        </fieldset>
                        <label
                            for="open-new-ticket-file"
                            class="flex items-center justify-center w-64 h-28 my-6 ms-3 px-xs relative cursor-pointer border border-dashed border-2 border-primary-400 rounded-lg"
                        >
                            <div
                                class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                            >
                                <img
                                    src="@qariin-fe/src/images/icons/upload.svg"
                                    alt="upload"
                                />
                            </div>
                            <div v-if="input.file" class="flex flex-col items-center justify-center">
                                <figure class="w-10 h-10 rounded-full bg-primary p-2 mb-3">
                                    <img
                                        src="@qariin-fe/src/images/icons/done.svg"
                                        alt="Done"
                                    />
                                </figure>
                                <span class="text-sm text-secondary">
                                    {{ input.file }}
                                </span>
                            </div>
                            <img
                                v-else
                                src="@qariin-fe/src/images/icons/upload-primary.svg"
                                alt="Upload file"
                                class="w-28"
                            />
                            <input
                                class="w-0 h-0"
                                name="file"
                                id="open-new-ticket-file"
                                type="file"
                                @change="setFile($event)"
                            />
                        </label>
                        <button v-if="!isAddingNewTicket" type="submit"
                            class="w-full max-w-xs my-5 px-xs bg-primary text-white rounded-sm"
                            :class="{ disabled: !newTicketIsValid }" :disabled="!newTicketIsValid"
                            @click.prevent="addNewTicket">
                            ارسال
                        </button>
                        <div v-else class="flex items-center justify-center">
                            <VueLottiePlayer style="width: 150px; height: 100px" name="Qariin loader" loop
                                path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json" />
                        </div>
                    </form>
                </Modal>
                <Modal ref="compalintsModal" title="عرض آلية الشكوى">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="border-e">
                            <p class="text-tertiary">
                                يمكنكم تقديم شكواكم من خلال القنوات التالية :
                            </p>
                            <div class="w-full flex items-center justify-between border-b">
                                <span class="text-black py-3">1/ الرقم المجاني</span>
                                <a href="tel:+9668001111051" class="pe-4">(8001111051)</a>
                            </div>
                            <div class="w-full flex items-center justify-between border-b">
                                <span class="text-black py-3">2/ تطبيق قارن</span>
                                <p class="flex items-center justify-center pe-4">
                                    <a href="#" target="__blank">
                                        أيفون
                                    </a>
                                    <span class="px-2 text-black">|</span>
                                    <a href="#" target="__blank">
                                        أندرويد
                                    </a>
                                </p>
                            </div>
                            <div class="w-full flex items-center justify-between border-b">
                                <span class="text-black py-3">3/ البريد الإلكتروني</span>
                                <a href="mailto:support@qariin.com" class="pe-4">( support@qariin.com )</a>
                            </div>
                            <div class="w-full flex items-center justify-between">
                                <span class="text-black py-3 border-b">4/ الموقع الالكتروني</span>
                                <a href="qariin.com" target="__blank" class="pe-4">qariin.com</a>
                            </div>
                            <div class="w-full flex items-center justify-between border-b">
                                <span class="text-black py-3">
                                    5/ مواقع التواصل الاجتماعي
                                </span>
                                <div>
                                    <a target="__blank" href="https://mobile.twitter.com/qariinksa">
                                        <div class="p-2 w-8 h-8">
                                            <img src="~@qariin-fe/src/images/svg/twitter.svg" alt="twitter"
                                                class="max-w-full max-h-full w-8 h-8" />
                                        </div>
                                    </a>
                                    <a target="__blank" href="https://www.facebook.com/QariinKSA">
                                        <div class="p-2 w-8 h-8">
                                            <img src="~@qariin-fe/src/images/svg/facebook.svg" alt="Facebook"
                                                class="max-w-full max-h-full w-8 h-8" />
                                        </div>
                                    </a>
                                    <a target="__blank" href="https://instagram.com/qariinksa">
                                        <div class="p-2 w-8 h-8">
                                            <img src="~@qariin-fe/src/images/svg/instagram.svg" alt="instagram"
                                                class="max-w-full max-h-full w-8 h-8" />
                                        </div>
                                    </a>
                                    <a target="__blank" href="https://www.linkedin.com/company/%D9%82%D8%A7%D8%B1%D9%86-%D8%A7%D9%84%D9%88%D8%B3%D9%8A%D8%B7-%D9%84%D9%88%D8%B3%D8%A7%D8%B7%D8%A9-%D8%A7%D9%84%D8%AA%D8%A3%D9%85%D9%8A%D9%86-%D8%A7%D9%84%D8%A7%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A/">
                                        <div class="p-2 w-8 h-8">
                                            <img src="~@qariin-fe/src/images/svg/linkedin.svg" alt="linkedin"
                                                class="max-w-full max-h-full w-8 h-8" />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div class="w-full flex items-center justify-between border-b">
                                <span class="text-black py-3">
                                    6/ زيارة إدارة الشكاوى في الشركة
                                </span>
                            </div>
                        </div>
                        <div class="text-black text-sm">
                            <p class="bg-warning-light text-text-warning p-5">
                                في حال تم تقديم شكوى فإن إدارة الشكاوى تتجاوب معها فورأ وتحيلها إلى الإدارة المعنية بالشركة لدراستها وحلها ثم إشعاركم بما تم حيالها وذلك وفقا للاجراءات التالية
                            </p>
                            <p class="py-3">1/ اشعاركم باستلام الشكوى خلال 8 ساعات عمل من إرساله، مع تسليمكم رقم المرجع الذي تستخدمه للمتابعة</p>
                            <p class="py-3">2/ تقوم إدارة الشكاوى بإرسال ملاحظاتك مباشرة إلى الإدارة المعنية عبر نظام آلي متطور للتحقيق فيها واتخاذ الإجراءات المناسبة خلال مدة لاتتجاوز 10 أيام عمل من تاريخ استلام الطلب .</p>
                            <p class="py-3">3/ إذا لم تقم الإدارة المعنية بالرد ، يرفع النظام ملاحظاتك أو شكواك آلياً إلى المستوى الإداري الأعلى حتى تصل إلى أعلى مستوى إداري بالشركة للرد عليها .</p>
                            <p class="py-3">4/ في حال وجود أي نواقص تقوم إدارة الشكاوى بالتواصل معكم وطلبها منكم .</p>
                            <p class="py-3">5/ في حال لم يتم الرد على شكواكم بعد مرور 5 أيام عمل أو عدم الرضا على حل الشكوى يمكنكم التوجه لتقديم شكوى في نظام <a href="https://www.samacares.sa/">ساما تهتم</a></p>
                        </div>
                    </div>
                </Modal>
                <Modal ref="ticketMessages" title="المحادثة">
                    <div>
                        <div class="grid grid-cols-5 gap-2 p-xs bg-white mb-5 text-sm pb-3 border-b">
                            <div class="grid grid-cols-5 gap-3 col-span-5 pb-4 border-b">
                                <span class="text-secondary">رقم التذكرة</span>
                                <span class="block col-span-3 text-black">{{
                                    currentTicket.id
                                    }}</span>
                                <div class="col-span-5 md:col-span-1">
                                    <span class="text-black pe-2">الحالة :</span>
                                    <span v-if="currentTicket.status == 'active'" class="text-success">نشطة</span>
                                    <span v-if="currentTicket.status == 'pending'" class="text-warning">جاري
                                        التحقيق</span>
                                    <span v-if="currentTicket.status == 'closed'" class="text-secondary">مغلق</span>
                                </div>
                            </div>
                            <span class="text-secondary">نوع التذكرة</span>
                            <p class="col-span-3 text-black">
                                {{ currentTicket.reason }}
                            </p>
                            <span class="text-secondary text-xs text-end" style="direction: ltr">{{
                                formateDate(currentTicket.created_at)
                                }}</span>
                            <template v-if="
                                    currentTicket.vehicle &&
                                    currentTicket.vehicle.id
                                ">
                                <span class="text-secondary">وصف المركبة</span>
                                <p class="col-span-4 text-black">
                                    {{ currentTicket.vehicle.maker?.name }}
                                    {{
                                    currentTicket.vehicle.vehicle_model
                                    ?.name
                                    }}
                                    {{ currentTicket.vehicle.model_year }}
                                    {{ currentTicket.vehicle.major_color }}
                                </p>
                            </template>
                            <span class="text-secondary">الرسالة</span>
                            <p class="col-span-5 md:col-span-3 text-black">
                                {{ currentTicket.message }}
                            </p>
                        </div>

                        <div v-for="message in currentTicket.messages" :key="message.id"
                            class="mb-3 p-xs border-b grid grid-cols-5 gap-2">
                            <span v-if="message.employee?.id" class="text-black">العنايه بالعملاء</span>
                            <span v-else-if="message.from_user" class="text-black">رسالتك</span>
                            <p class="col-span-3 text-black">
                                {{ message.message }}
                            </p>
                            <div class="flex flex-col items-start justify-start">
                                <span class="text-secondary text-xs text-end" style="direction: ltr">{{
                                    formateDate(message.created_at) }}</span>
                                <span v-if="message.employee?.id" class="text-secondary text-xs">{{
                                    message.employee.name }}</span>
                            </div>
                        </div>

                        <form ref="reply" :action="route('ticketMessages.store')" method="post" class="w-full">
                            <input type="hidden" name="_token" :value="csrf" />
                            <input type="hidden" name="ticket_id" :value="currentTicket.id" />
                            <fieldset class="input input-w-full w-full bg-white mt-5">
                                <textarea v-model="input.reply" name="message" class="w-full h-32 resize-none"
                                    placeholder="رسالتك هنا" required></textarea>
                            </fieldset>
                            <button v-if="!isReplying" type="submit"
                                class="w-full max-w-xs my-5 px-xs bg-primary text-white rounded-sm"
                                :class="{ disabled: !input.reply }" :disabled="!input.reply" @click.prevent="reply">
                                ارسال
                            </button>
                            <div v-else class="flex items-center justify-center">
                                <VueLottiePlayer style="width: 150px; height: 100px" name="Qariin loader" loop
                                    path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json" />
                            </div>
                        </form>
                    </div>
                </Modal>
                <Modal ref="vehicleDeleteModal" title="حذف المركبه">
                    <div>
                        <h2 class="text-black">
                            هل انت متأكد من حذف هذه المركبه؟
                        </h2>
                        <p class="mt-1 text-tertiary text-sm">
                            في حاله حذف المركبه لن تتمكن من استراجعها وسيتم حذف
                            كل الطلبات المتعلقه بها.
                        </p>
                        <div class="mt-5 pt-3 border-t">
                            <form :action="
                                    route('vehicles.destroy', currentVehicle.id)
                                " method="post">
                                <input type="hidden" name="_method" value="delete" />
                                <input type="hidden" name="_token" :value="csrf" />
                                <button type="button"
                                    class="bg-white py-2 px-sm rounded-md cursor-pointer text-tertiary" @click="
                                        $refs.vehicleDeleteModal.toggleModal()
                                    ">
                                    إلغاء
                                </button>
                                <button type="submit" class="bg-danger py-2 px-sm rounded-md cursor-pointer text-white">
                                    حذف
                                </button>
                            </form>
                        </div>
                    </div>
                </Modal>

                <!-- Settings -->
                <div v-if="routes.settings">
                    <div v-if="$page.props.jetstream.canUpdateProfileInformation">
                        <update-profile-information-form :user="$page.props.user" />

                        <jet-section-border />
                    </div>

                    <div v-if="$page.props.jetstream.canUpdatePassword">
                        <update-password-form class="mt-10 sm:mt-0" />

                        <jet-section-border />
                    </div>
                    <!-- <div
                        v-if="
                            $page.props.jetstream.canManageTwoFactorAuthentication
                        "
                    >
                        <two-factor-authentication-form class="mt-10 sm:mt-0" />

                        <jet-section-border />
                    </div>

                    <logout-other-browser-sessions-form
                        :sessions="sessions"
                        class="mt-10 sm:mt-0"
                    /> -->

                    <template
                        v-if="$page.props.jetstream.hasAccountDeletionFeatures"
                    >
                        <jet-section-border />

                        <delete-user-form class="mt-10 sm:mt-0" />
                    </template>
                </div>
            </div>
        </div>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import { defineComponent } from "vue";
import AppLayout from "@/Layouts/AppLayout.vue";
import DeleteUserForm from "@/Pages/Profile/Partials/DeleteUserForm.vue";
import JetSectionBorder from "@/Jetstream/SectionBorder.vue";
// import LogoutOtherBrowserSessionsForm from "@/Pages/Profile/Partials/LogoutOtherBrowserSessionsForm.vue";
// import TwoFactorAuthenticationForm from "@/Pages/Profile/Partials/TwoFactorAuthenticationForm.vue";
import UpdatePasswordForm from "@/Pages/Profile/Partials/UpdatePasswordForm.vue";
import UpdateProfileInformationForm from "@/Pages/Profile/Partials/UpdateProfileInformationForm.vue";
import Orders from "@/Pages/Profile/Partials/Orders.vue";
import Modal from "@/components/Modal.vue";
import * as moment from "moment";
import VueLottiePlayer from "vue-lottie-player";

export default defineComponent({
    props: {
        sessions: {
            type: Array,
            required: true,
        },
        vehicles: {
            type: Array,
            required: true,
        },
        notifications: {
            type: Array,
            required: true,
        },
        tickets: {
            type: Array,
            required: true,
        },
        identities: {
            type: Array,
            required: true,
        },
        completedOrders: {
            type: Array,
            required: true,
        },
        notCompletedOrders: {
            type: Array,
            required: true,
        },
        csrf: {
            type: String,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        policies: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    components: {
        AppLayout,
        Head,
        JetSectionBorder,
        UpdatePasswordForm,
        UpdateProfileInformationForm,
        Orders,
        Modal,
        VueLottiePlayer,
        DeleteUserForm,
    },
    data() {
        return {
            routes: {
                home: true,
                vehicles: false,
                address: false,
                orders: false,
                support: false,
                notifications: false,
                settings: false,
            },
            input: {
                ticketCategory: null,
                ticketType: null,
                vehicle: null,
                message: null,
                reply: null,
                file: null,
            },
            categories: [
                {
                    code: "complaint",
                    name: "شكوى",
                },
                {
                    code: "inquiry",
                    name: "استفسار",
                },
                {
                    code: "request",
                    name: "طلب",
                },
                {
                    code: "observations",
                    name: "اراء و ملاحظات",
                },
            ],
            ticketTypes: [
                "تعديل بيانات وثيقة التامين",
                "عدم القدرة علي طباعة وثيقة التامين",
                "اصدار وثيقة التامين",
                "العنوان الوطني",
                "اخرى",
            ],
            currentTicket: null,
            confirmingVehicleDeletion: false,
            currentVehicle: null,
            isAddingNewTicket: false,
            isReplying: false,
        };
    },
    computed: {
        testCompletedOrder() {
            const orders = [this.notCompletedOrders[0]];
            return orders;
        },
        newTicketIsValid() {
            var isValid = false;

            if (
                this.input.ticketCategory &&
                this.input.ticketType &&
                this.input.message
            )
                isValid = true;

            if (
                this.input.ticketType == "اخرى" &&
                this.input.vehicle &&
                this.input.message &&
                this.input.ticketCategory
            )
                isValid = true;

            return isValid;
        },
    },
    methods: {
        setFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            this.input.file = files[0].name;
        },
        getImgUrl(path){
            try{
                if (!path) throw new Error();
                return path
            }catch(_){
                return require('@qariin-fe/src/images/default-car-logo.png').default
            }
        },
        getAttachment(path){
            try{
               return '/storage/customers/tickets/' + path ;
               //require(`@storage/customers/tickets/${path}`).default ;
            }catch(e){
                console.log(e);
                return '' ;
            }
        },
        logout() {
            this.$inertia.post(route("logout"));
        },
        toggleTarget(passedTarget) {
            const target =
                passedTarget ||
                window.location.hash.slice(1, window.location.hash.length);

            if (!target) return;
            Object.keys(this.routes).forEach((key) => {
                this.routes[key] = false;
                this.routes[target] = true;
            });

            if (target == 'settings') localStorage.setItem('otp_reason', 'change-data');
        },
        formateDate(date) {
            return `${moment(date).format("DD/MM/YYYY  hh:mm a")}`;
        },
        reply() {
            this.isReplying = true;
            this.$refs.reply.submit();
        },
        addNewTicket() {
            this.isAddingNewTicket = true;
            this.$refs.addTicket.submit();
        },
        openMessages(index) {
            this.currentTicket = this.tickets[index];
            this.$refs.ticketMessages.toggleModal();
        },
        confirmVehicleDeletion(vehicle) {
            this.currentVehicle = vehicle;
            this.$refs.vehicleDeleteModal.toggleModal();
        },
        calculateDays(expiry) {
            var oneDay = 24 * 60 * 60 * 1000;
            var date1 = Date.now();
            var date2 = new Date(expiry);
            return `${Math.round(Math.abs((date1 - date2) / oneDay))} يوم`;
        },
        getDaysPercentage(expiry) {
            var oneDay = 24 * 60 * 60 * 1000;
            var date1 = Date.now();
            var date2 = new Date(expiry);
            return (Math.round(Math.abs((date1 - date2) / oneDay)) / 365) * 100;
        },
    },
    mounted() {
        const target = window.location.hash.slice(
            1,
            window.location.hash.length
        );
        if (!target) this.toggleTarget("home");
        else this.toggleTarget();

        if (target == "support") {
            var params = new URLSearchParams(window.location.search); // query params

            if (params.has("ticketId")) {
                var id = params.get("ticketId");

                this.currentTicket = this.tickets.find(
                    (ticket) => ticket.id == id
                );

                this.currentTicket.id &&
                    this.$refs.ticketMessages.toggleModal();
            }
        } else if (target == 'settings') {
            localStorage.setItem('otp_reason', 'change-data');
        }
    },
});
</script>
