<template>
    <app-layout>
        <Head>
            <title>Payment | Qariin</title>
        </Head>
        <ErrorsAlert
            v-if="(errorMessages && errorMessages.length) || (validationsErrorMessages && validationsErrorMessages.length)"
            :errors="errorMessages && errorMessages.length ? errorMessages : validationsErrorMessages"
        />
        <Modal ref="QrCodeModal" title="امسح الرمز باستخدام الجوال">
            <div class="flex items-center justify-center" v-html="qrCode"></div>
        </Modal>
        <Modal ref="otpModal" title="برجاء كتابه كود التفعيل">
            <form
                @submit.prevent="validateOTP"
                class="flex flex-col items-center justify-center py-12"
            >
                <h1 class="text-4xl text-secondary-900 mb-2">
                    ادخل كود التفعيل
                </h1>
                <p class="text-tertiary text-sm max-w-sm mb-8">
                    أدخل رمز التحقق الذي تم ارساله علي رقم جوالك
                </p>
                <fieldset class="input password">
                    <label for="otp">ادخل كود التفعيل</label>
                    <input
                        type="number"
                        id="otp"
                        required
                        name="otp"
                        placeholder="ادخل كود التفعيل هنا..."
                        v-model="input.otp"
                        maxlength="4"
                        @input="validateLength($event, 'otp')"
                    />
                </fieldset>
                <button
                    type="submit"
                    class="bg-primary text-white w-64 rounded-md"
                    :class="{ disabled: otpValidationIsLoading }"
                >
                    تأكيد
                </button>
                <p>
                    <span class="text-sm text-tertiary">
                        إذا لم تتلقى رمز اضغط على
                    </span>
                    <button
                        @click.prevent="resendCode"
                        :class="{ 'opacity-25': timerEnabled }"
                        :disabled="timerEnabled"
                    >
                        إعادة إرسال
                        <span v-if="timerCount != 60" class="ps-2">{{
                            timerCount
                        }}</span>
                    </button>
                </p>
                <span
                    v-if="otpHasError"
                    class="inline-block mt-1 text-danger text-sm"
                    >برجاء التأكد من كود التفعيل</span
                >
                <span
                    v-if="resendHasError"
                    class="inline-block mt-1 text-danger text-sm"
                    >حدث خطأ اثناء إعادة الإرسال, برجاء المحاولة مرة اخرى</span
                >

                <p
                    class="mb-4 font-medium text-sm text-green-600 text-center"
                    v-if="verificationOTPSent"
                >
                    تم ارسال كود تفعيل جديد.
                </p>
            </form>
        </Modal>
        <section class="payment">
            <div class="container">
                <h1 class="flex items-center justify-between mb-6 pt-6 text-secondary-900 text-lg lg:text-2xl">
                    <span>ملخص الفاتورة</span>
                    <a :href="`${route('offers')}?id=${data.search_id}`">
                        <span class="text-sm">العودة إلى العروض</span>
                        <img :src="require('@qariin-fe/src/images/icons/back.svg').default" class="w-6 ms-2" alt="Back button">
                    </a>
                </h1>

                <div class="relative">
                    <form
                        :action="route('orders.store')"
                        method="post"
                        @submit.prevent="payNow($event)"
                        ref="pay"
                        enctype="multipart/form-data"
                        class="w-full grid grid-cols-1 lg:grid-cols-4 place-items-start gap-4"
                    >
                        <div class="payment__details">
                            <div class="payment__summary">
                                <figure class="w-44 h-12">
                                    <img
                                        v-if="data.company.avatar"
                                        :src="
                                        `/storage/${data.company.avatar}`
                                            // require(`@storage/${data.company.avatar}`)
                                                // .default
                                        "
                                        alt="Alrajhi Co"
                                        class="max-w-full max-h-full object-contain"
                                    />
                                </figure>

                                <div class="payment__totalPrice">
                                    <h3
                                        class="text-normal lg:text-lg text-secondary"
                                    >
                                        <span class="pe-2">الإجمالي</span>
                                        <span
                                            >{{
                                                data.total_price_after_promotional.toFixed(
                                                    2
                                                )
                                            }}
                                            ﷼</span
                                        >
                                    </h3>

                                    <small class="text-tertiary text-center block text-xs">
                                      {{ data.product_type_code == 1 ? "شامل عمولة الوسيط 2%" : "شامل عمولة الوسيط %15" }}
                                    </small>
                                </div>
                            </div>
                            <div class="payment__vehicle">
                                <article>
                                    <div>
                                        <span class="test-secondary text-xs">
                                            نوع التأمين
                                        </span>
                                        <p class="text-black text-sm">
                                            {{
                                                data.product_type
                                                    .arabic_description
                                            }}
                                        </p>
                                    </div>
                                </article>
                                <article>
                                    <div>
                                        <span class="test-secondary text-xs">
                                            وصف المركبة
                                        </span>
                                        <p class="text-black text-sm">
                                            {{ data.vehicle?.maker?.name }}
                                            {{
                                                data.vehicle?.vehicle_model
                                                    ?.name
                                            }}
                                            {{ data.vehicle?.model_year }}
                                            {{ data.vehicle?.major_color }}
                                        </p>
                                    </div>
                                </article>
                                <article
                                    v-if="
                                        data.vehicle &&
                                        data.vehicle.plate_number
                                    "
                                >
                                    <figure
                                        class="payment__plate"
                                        :data-plate-number="`${data.vehicle.plate_number}`"
                                    >
                                        <img
                                            src="~@qariin-fe/src/images/offers/car-plate.jpg"
                                            class="w-full h-full object-contain"
                                            alt="Car Plate"
                                        />
                                        <span
                                            v-if="
                                                data.vehicle.plate_letter1
                                                    .arabic_letter
                                            "
                                            class="payment__plate__arabic"
                                            >{{
                                                `${data.vehicle.plate_letter3.arabic_letter} ${data.vehicle.plate_letter2.arabic_letter} ${data.vehicle.plate_letter1.arabic_letter}`
                                            }}</span
                                        >
                                        <span class="payment__plate__english">{{
                                            `${data.vehicle.plate_letter1.english_letter} ${data.vehicle.plate_letter2.english_letter} ${data.vehicle.plate_letter3.english_letter}`
                                        }}</span>
                                    </figure>
                                </article>
                                <article>
                                    <div>
                                        <span class="test-secondary text-xs">
                                            الرقم التسلسلي/الجمركي
                                        </span>
                                        <p class="text-black text-sm">
                                            {{
                                                data.vehicle.number ||
                                                data.vehicle.custom_number
                                            }}
                                        </p>
                                    </div>
                                </article>
                                <article>
                                    <div>
                                        <span class="test-secondary text-xs">
                                            تاريخ بدء الوثيقة
                                        </span>
                                        <p class="text-black text-sm">
                                            {{
                                                formatDate(
                                                    data.insurance_start_year
                                                )
                                            }}
                                        </p>
                                    </div>
                                </article>
                                <article>
                                    <div>
                                        <span class="test-secondary text-xs">
                                            تاريخ إنتهاء الوثيقة
                                        </span>
                                        <p class="text-black text-sm">
                                            {{
                                                formatDate(
                                                    data.insurance_expired_year
                                                )
                                            }}
                                        </p>
                                    </div>
                                </article>
                            </div>
                            <div class="payment__applicant">
                                <h3 class="text-tertiary pb-3 border-b">
                                    بيانات طالب التأمين
                                </h3>
                                <form
                                    @submit.prevent
                                    ref="mainForm"
                                    class="my-3 grid grid-cols-1 md:grid-cols-2 gap-4"
                                >
                                    <fieldset class="selectbox">
                                        <label for="bank-offers"
                                            >أختر البنك</label
                                        >
                                        <select
                                            name="bank-offers"
                                            id="bank-offers"
                                            v-model="input.bank_id"
                                            class="text-primary"
                                        >
                                            <option
                                                value="null"
                                                selected
                                                disabled
                                            >
                                                اختر البنك
                                            </option>
                                            <option
                                                v-for="bank in banks"
                                                :key="bank.id"
                                                :value="bank.id"
                                            >
                                                {{ bank.arabic_description }}
                                            </option>
                                        </select>
                                    </fieldset>
                                    <fieldset class="input input-w-auto">
                                        <label for="iban-number"> IBAN </label>
                                        <label
                                            class="iban-prefix absolute left-0 top-0 h-full text-center bg-gray-100 p-6"
                                            >SA</label
                                        >
                                        <input
                                            v-model="input.iban"
                                            type="text"
                                            id="iban-number"
                                            required
                                            name="iban-number"
                                            placeholder="اكتب رقم الحساب الدولي..."
                                            maxlength="22"
                                            @focus="showIbanDisclaimer = true"
                                            @blur="showIbanDisclaimer = false"
                                        />
                                        <p class="text-primary text-xs opacity-0 mt-1 pe-5" :class="{'opacity-100': showIbanDisclaimer }">اتعهد ان معلومات الايبان البنكي المدخلة صحيحة وتعود الى مالك الوثيقة</p>
                                    </fieldset>
                                    <fieldset
                                        class="input input-w-auto input-disabled"
                                    >
                                        <label for="applicant-name">
                                            اسم طالب التامين
                                        </label>
                                        <input
                                            v-model="input.applicant_name"
                                            type="text"
                                            id="applicant-name"
                                            required
                                            name="applicant-name"
                                            placeholder="اكتب اسم طالب التامين"
                                            disabled
                                        />
                                    </fieldset>
                                    <fieldset class="input input-w-auto">
                                        <label for="applicant-email">
                                            البريد الإلكتروني لطالب التأمين
                                        </label>
                                        <input
                                            v-model="input.applicant_email"
                                            type="email"
                                            id="applicant-email"
                                            required
                                            name="applicant-email"
                                            placeholder="اكتب البريد الإلكتروني لطالب التأمين"
                                        />
                                    </fieldset>
                                    <fieldset
                                        class="input input-w-auto input-disabled"
                                    >
                                        <label for="applicant-city">
                                            مدينة إقامة السائق
                                        </label>
                                        <input
                                            v-model="input.applicant_city"
                                            type="text"
                                            id="applicant-city"
                                            required
                                            name="applicant-city"
                                            placeholder="اكتب مدينة إقامة السائق"
                                            disabled
                                        />
                                    </fieldset>
                                    <fieldset class="input input-w-auto">
                                        <label for="applicant-name">
                                            رقم الجوال لطالب التأمين
                                        </label>
                                        <input
                                            v-model="input.applicant_phone"
                                            type="tel"
                                            id="applicant-phone"
                                            required
                                            name="applicant-phone"
                                            placeholder="اكتب رقم الجوال لطالب التأمين"
                                            @input="
                                                validateLength(
                                                    $event,
                                                    'applicant_phone',
                                                    true
                                                )
                                            "
                                        />
                                    </fieldset>
                                    <fieldset
                                        class="input input-w-auto input-disabled"
                                    >
                                        <label for="applicant-id">
                                            رقم الهوية/ الإقامة
                                        </label>
                                        <input
                                            v-model="input.applicant_id"
                                            type="tel"
                                            id="applicant-id"
                                            required
                                            name="applicant-id"
                                            placeholder="اكتب رقم الهوية/ الإقامة"
                                            disabled
                                        />
                                    </fieldset>
                                </form>
                            </div>

                            <div
                                v-if="data.product_type_code != 1 && !data.vehicle?.custom_number"
                                class="payment__images mt-5"
                            >
                                <h3 class="text-tertiary pb-3 border-b">
                                    صور المركبة
                                </h3>
                                <p
                                    v-if="!isMobile"
                                    class="payment__images__warning"
                                    @click="showQRCode"
                                >
                                    <img
                                        src="@qariin-fe/src/images/icons/payment-images-warning.svg"
                                        alt="Payment images warning"
                                        class="w-8 me-3"
                                    />
                                    <span>
                                        في حالة عدم تمكنك من تصوير السيارة من
                                        خلال متصفح الجهاز .. يمكنكم عن طريق
                                        النقر هنا ثم مسح ال QR كود لاستكمال
                                        الاجراءات من متصفح الهاتف
                                    </span>
                                </p>

                                <div
                                    v-else
                                    class="grid grid-cols-2 md:grid-cols-3 md:grid-cols-5 gap-3 justify-items-center"
                                >
                                    <label
                                        for="car-front"
                                        class="block flex items-center justify-center w-28 h-28 my-6 ms-3 px-xs relative cursor-pointer border rounded-md"
                                    >
                                        <div
                                            class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                                        >
                                            <img
                                                ref="image_front_done"
                                                class="hidden"
                                                src="@qariin-fe/src/images/icons/done.svg"
                                                alt="Done"
                                            />
                                            <img
                                                ref="image_front_camera"
                                                src="@qariin-fe/src/images/payment/camera.svg"
                                                alt="Camera"
                                            />
                                        </div>
                                        <div
                                            ref="image_front"
                                            class="my-2"
                                        ></div>
                                        <img
                                            ref="image_front_tag"
                                            src="@qariin-fe/src/images/payment/car-front.jpg"
                                            alt="Car Front"
                                            class="max-w-full"
                                        />
                                        <input
                                            class="w-0 h-0"
                                            @change="
                                                setImage($event, 'image_front')
                                            "
                                            name="vehicle_images[0][image]"
                                            id="car-front"
                                            type="file"
                                            accept="image/*"
                                            capture="camera"
                                        />
                                        <input
                                            name="vehicle_images[0][image_title_id]"
                                            type="hidden"
                                            :value="image_titles['image_front']"
                                        />
                                    </label>
                                    <label
                                        for="car-side-left"
                                        class="block flex items-center justify-center w-28 h-28 my-6 ms-3 px-xs relative cursor-pointer border rounded-md"
                                    >
                                        <div
                                            class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                                        >
                                            <img
                                                ref="image_left_done"
                                                class="hidden"
                                                src="@qariin-fe/src/images/icons/done.svg"
                                                alt="Done"
                                            />
                                            <img
                                                ref="image_left_camera"
                                                src="@qariin-fe/src/images/payment/camera.svg"
                                                alt="Camera"
                                            />
                                        </div>
                                        <div
                                            ref="image_left"
                                            class="my-2"
                                        ></div>
                                        <img
                                            ref="image_left_tag"
                                            src="@qariin-fe/src/images/payment/car-side-left.jpg"
                                            alt="Car side-left"
                                            class="max-w-full"
                                        />
                                        <input
                                            class="w-0 h-0"
                                            @change="
                                                setImage($event, 'image_left')
                                            "
                                            name="vehicle_images[1][image]"
                                            id="car-side-left"
                                            type="file"
                                            accept="image/*"
                                            capture="camera"
                                        />
                                        <input
                                            name="vehicle_images[1][image_title_id]"
                                            type="hidden"
                                            :value="image_titles['image_left']"
                                        />
                                    </label>
                                    <label
                                        for="car-side-right"
                                        class="block flex items-center justify-center w-28 h-28 my-6 ms-3 px-xs relative cursor-pointer border rounded-md"
                                    >
                                        <div
                                            class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                                        >
                                            <img
                                                ref="image_right_done"
                                                class="hidden"
                                                src="@qariin-fe/src/images/icons/done.svg"
                                                alt="Done"
                                            />
                                            <img
                                                ref="image_right_camera"
                                                src="@qariin-fe/src/images/payment/camera.svg"
                                                alt="Camera"
                                            />
                                        </div>
                                        <div
                                            ref="image_right"
                                            class="my-2"
                                        ></div>
                                        <img
                                            ref="image_right_tag"
                                            src="@qariin-fe/src/images/payment/car-side-right.jpg"
                                            alt="Car side-right"
                                            class="max-w-full"
                                        />
                                        <input
                                            class="w-0 h-0"
                                            @change="
                                                setImage($event, 'image_right')
                                            "
                                            name="vehicle_images[2][image]"
                                            id="car-side-right"
                                            type="file"
                                            accept="image/*"
                                            capture="camera"
                                        />
                                        <input
                                            name="vehicle_images[2][image_title_id]"
                                            type="hidden"
                                            :value="image_titles['image_right']"
                                        />
                                    </label>
                                    <label
                                        for="car-back"
                                        class="block flex items-center justify-center w-28 h-28 my-6 ms-3 px-xs relative cursor-pointer border rounded-md"
                                    >
                                        <div
                                            class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                                        >
                                            <img
                                                ref="image_back_done"
                                                class="hidden"
                                                src="@qariin-fe/src/images/icons/done.svg"
                                                alt="Done"
                                            />
                                            <img
                                                ref="image_back_camera"
                                                src="@qariin-fe/src/images/payment/camera.svg"
                                                alt="Camera"
                                            />
                                        </div>
                                        <div
                                            ref="image_back"
                                            class="my-2"
                                        ></div>
                                        <img
                                            ref="image_back_tag"
                                            src="@qariin-fe/src/images/payment/car-back.jpg"
                                            alt="Car back"
                                            class="max-w-full"
                                        />
                                        <input
                                            class="w-0 h-0"
                                            @change="
                                                setImage($event, 'image_back')
                                            "
                                            name="vehicle_images[3][image]"
                                            id="car-back"
                                            type="file"
                                            accept="image/*"
                                            capture="camera"
                                        />
                                        <input
                                            name="vehicle_images[3][image_title_id]"
                                            type="hidden"
                                            :value="image_titles['image_back']"
                                        />
                                    </label>
                                    <label
                                        for="car-top"
                                        class="block flex items-center justify-center w-28 h-28 my-6 ms-3 px-xs relative cursor-pointer border rounded-md"
                                    >
                                        <div
                                            class="absolute -top-3 -right-3 w-10 h-10 rounded-full bg-primary p-2"
                                        >
                                            <img
                                                ref="image_vin_done"
                                                class="hidden"
                                                src="@qariin-fe/src/images/icons/done.svg"
                                                alt="Done"
                                            />
                                            <img
                                                ref="image_vin_camera"
                                                src="@qariin-fe/src/images/payment/camera.svg"
                                                alt="Camera"
                                            />
                                        </div>
                                        <div ref="image_vin" class="my-2"></div>
                                        <img
                                            ref="image_vin_tag"
                                            src="@qariin-fe/src/images/payment/car-top.jpg"
                                            alt="Car top"
                                            class="max-w-full"
                                        />
                                        <input
                                            class="w-0 h-0"
                                            @change="
                                                setImage($event, 'image_vin')
                                            "
                                            name="vehicle_images[4][image]"
                                            id="car-top"
                                            type="file"
                                            accept="image/*"
                                            capture="camera"
                                        />
                                        <input
                                            name="vehicle_images[4][image_title_id]"
                                            type="hidden"
                                            :value="image_titles['image_vin']"
                                        />
                                    </label>
                                </div>
                                <p v-if="input.hasImageDuplicates" class="text-danger">
                                    عذراَ, لا يمكنك اختيار نفس الصورة لاكثر من مرة
                                </p>
                                <fieldset class="my-2">
                                    <input
                                        v-model="input.images_confirmed"
                                        type="checkbox"
                                        id="confirmed"
                                    />
                                    <label for="confirmed" class="text-xs">
                                        اقر بأن الصور المرفوعة هي الصور الحقيقية للمركبة المؤمن عليها، والتي تم أخذها مؤخرا ولم يتم عليها التعديل بأي شكل من الأشكال. واقر بمعرفتي بأن في حال اثبات عكس ذلك فإن هذا سيؤدي إلى الغاء وثيقة التأمين وسيؤثر على المطالبات المرفوعة على المركبة.
                                    </label>
                                </fieldset>
                                <p class="text-primary">
                                    
                                </p>
                            </div>
                            <div class="payment__price">
                                <h3 class="text-tertiary pb-3 border-b">
                                    تفاصيل السعر
                                </h3>

                                <div class="payment__price__details">
                                    <div class="payment__price__item border-b-0">
                                        <p>
                                            <span> القسط الأساسي </span>
                                        </p>
                                        <span class="whitespace-nowrap">
                                            {{
                                                getMainAmount(
                                                    data.product
                                                ).toFixed(2)
                                            }}
                                            ﷼
                                        </span>
                                    </div>
                                    <p class="text-tertiary text-xs pb-4 max-w-md">
                                        الحد الأقصى لمسؤلية الشركة في الواقعة الواحدة وخلال فترة سريان وثيقة التأمين بالنسبة للأضرار الجسدية (بما في ذلك الديات والمبالغ المقدرة عن الإصابات والمصاريف الطبية) والأضرار المادية معاً لن تتجاوز مبلغاً إجمالياً قدره 10,000,000 ريال (عشرة ملايين ريال سعودي) حداً أقصى لمسؤلية الشركة
                                    </p>
                                    <div
                                        class="payment__price__item border-t pt-2 border-b-0"
                                        :key="item.id"
                                        v-for="item in getAddonsAndDiscounts(
                                            data.product
                                        )"
                                    >
                                        <small>{{
                                            item.price_type.arabic_description
                                        }}</small>
                                        <span class="whitespace-nowrap">
                                            {{ Number(item.amount).toFixed(2) }}
                                            ﷼
                                        </span>
                                    </div>
                                    <div
                                        v-if="getAdditionalCoverAmount() > 0"
                                        class="payment__price__item border-b-0"
                                    >
                                        <p>
                                            <span>
                                                المنافع الإضافية قبل الضريبة
                                            </span>
                                        </p>
                                        <span class="whitespace-nowrap">
                                            {{
                                                Number(
                                                    getAdditionalCoverAmount()
                                                ).toFixed(2)
                                            }}
                                            ﷼</span
                                        >
                                    </div>
                                    <ul v-if="data.covers.length" class="list-style-none pb-2">
                                        <li v-for="cover in data.covers" :key="cover.id" class="text-tertiary text-xs">
                                            <span>{{ cover.cover_name }}</span>
                                            <span class="ps-3">{{ cover.amount }} ر.س</span>
                                        </li>
                                    </ul>
                                    <div class="payment__price__item border-t pt-2">
                                        <p>
                                            <span>الإجمالي</span>
                                        </p>
                                        <span class="whitespace-nowrap">
                                            {{
                                                getTotalAmount(
                                                    data.product
                                                ).toFixed(2)
                                            }}
                                            ﷼
                                        </span>
                                    </div>
                                    <div class="payment__price__item">
                                        <p>
                                            <span> الضريبة المضافة </span>
                                        </p>
                                        <span class="whitespace-nowrap">
                                            <b>
                                                {{
                                                    getVat(
                                                        data.product
                                                    ).toFixed(2)
                                                }}
                                                ﷼
                                            </b>
                                        </span>
                                    </div>
                                    <div class="payment__price__item mt-4">
                                        <p>
                                            <b> المجموع المستحق </b>
                                        </p>
                                        <span class="whitespace-nowrap">
                                            {{
                                                Number(
                                                    getTotalAmount(
                                                        data.product
                                                    ) + getVat(data.product)
                                                ).toFixed(2)
                                            }}
                                            ﷼</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="payment__methods">
                            <h3 class="text-tertiary pb-3 border-b">
                                طريقة الدفع
                            </h3>
                            <div
                                v-if="isSafari"
                                class="flex items-center justify-between mt-5"
                            >
                                <fieldset>
                                    <input
                                        v-model="input.payment_method"
                                        value="apple"
                                        type="radio"
                                        name="payment-method"
                                        id="apple-pay"
                                    />
                                    <label for="apple-pay" class="text-sm"
                                        >Apple Pay</label
                                    >
                                </fieldset>
                                <img
                                    src="@qariin-fe/src/images/icons/apple-pay.svg"
                                    alt="Apple Pay"
                                    class="w-12"
                                />
                            </div>
                            <div class="flex items-center justify-between mt-3">
                                <fieldset>
                                    <input
                                        v-model="input.payment_method"
                                        value="mada"
                                        type="radio"
                                        name="payment-method"
                                        id="mada"
                                    />
                                    <label for="mada" class="text-sm"
                                        >Mada</label
                                    >
                                </fieldset>
                                <img
                                    src="@qariin-fe/src/images/svg/mada.svg"
                                    alt="Mada"
                                    class="w-12"
                                />
                            </div>
                            <div>
                                <fieldset class="mt-3">
                                    <input
                                        v-model="input.payment_method"
                                        value="visa/master"
                                        type="radio"
                                        name="payment-method"
                                        id="credit-card"
                                    />
                                    <label for="credit-card" class="text-sm"
                                        >Credit Card</label
                                    >
                                </fieldset>
                                <div
                                    class="flex flex-col items-start justfiy-center mt-2"
                                >
                                    <small class="text-tertiary"
                                        >ندعم بطاقات</small
                                    >
                                    <img
                                        src="@qariin-fe/src/images/supported-cards.jpg"
                                        alt="Supported cards"
                                    />
                                </div>
                            </div>
                            <input
                                type="hidden"
                                name="company_id"
                                :value="data.company.id"
                            />
                            <input
                                type="hidden"
                                name="product_id"
                                :value="data.product.id"
                            />
                            <input
                                type="hidden"
                                name="search_id"
                                :value="data.search_id"
                            />
                            <input
                                type="hidden"
                                name="quotation_id"
                                :value="data.quotation_id"
                            />
                            <input type="hidden" name="first_step" :value="0" />
                            <input
                                type="hidden"
                                name="iban"
                                :value="input.iban"
                            />
                            <input
                                type="hidden"
                                name="bank_id"
                                :value="input.bank_id"
                            />
                            <input
                                type="hidden"
                                name="order_id"
                                :value="data.id"
                            />
                            <input
                                type="hidden"
                                name="payment_type"
                                :value="input.payment_method"
                            />
                            <input
                                type="hidden"
                                name="mobile_number"
                                :value="validPhone"
                            />
                            <input
                                type="hidden"
                                name="email"
                                :value="input.applicant_email"
                            />
                            <input
                                type="hidden"
                                name="product_type_code"
                                :value="data.product_type == 'TPL' ? 1 : 2"
                            />
                            <input
                                type="hidden"
                                name="quotation_reference"
                                :value="data.product_type == 'TPL' ? 1 : 2"
                            />
                            <input type="hidden" name="_token" :value="csrf" />
                            <!-- <template v-for="(key, index) in Object.keys(image_titles)" :key="image_titles[key]">
                                <template v-if="!!input[key]">
                                    <input type="file" class="hidden" :name="`vehicle_images[${index}][image]`" :value="input[key]" />
                                    <input type="hidden" :name="`vehicle_images[${index}][image_title_id]`" :value="image_titles[key]" />
                                </template>
                            </template> -->
                            <button
                                v-if="!isLoading"
                                type="submit"
                                class="cursor-pointer bg-primary text-white rounded-md px-xs w-full mt-5 py-2 text-center"
                                :class="{disabled: !formIsValid,}"
                                :disabled="!formIsValid"
                                @click.prevent="payNow($event)"
                            >
                                ادفع الان
                            </button>
                            <div
                                v-else
                                class="flex items-center justify-center"
                            >
                                <VueLottiePlayer
                                    style="width: 150px; height: 100px"
                                    name="Qariin loader"
                                    loop
                                    path="https://assets9.lottiefiles.com/private_files/lf30_1xgata3z.json"
                                />
                            </div>

                            <div v-if="data.product_type_code == 2" class="mt-10">
                                <fieldset class="max-w-xs mb-3">
                                    <input
                                        v-model="input.confirmed"
                                        type="checkbox"
                                        id="confirmed"
                                    />
                                    <label for="confirmed" class="text-xs">بالنقر أقر بالاطلاع على شروط التأمين والموافقة عليها و أرغب في شراء وثيقة تأمين الشامل دون ادنى مسؤولية على منصة قارن , وأقر بأن المعلومات المدخلة والايبان البنكي لمالك الوثيقة صحيحة.</label>
                                </fieldset>
                            </div>
                            <div v-else >
                                <fieldset class="max-w-xs mb-3">
                                    <input
                                        v-model="input.terms_agreed"
                                        type="checkbox"
                                        id="terms-agreed"
                                    />
                                    <label for="terms-agreed" class="text-xs">بالنقر أقر بالاطلاع على شروط التأمين والموافقة عليها ولا أرغب في شراء وثيقة تأمين شامل دون ادنى مسؤولية على منصة قارن , وأقر بأن المعلومات المدخلة والايبان البنكي لمالك الوثيقة صحيحة.</label>
                                </fieldset>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </app-layout>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import AppLayout from "@/Layouts/AppLayout.vue";
import Modal from "@/components/Modal.vue";
import axios from "axios";
import ErrorsAlert from "@/components/ErrorsAlert.vue";
import * as moment from "moment";
import VueLottiePlayer from "vue-lottie-player";

export default {
    name: "Payment",
    components: {
        AppLayout,
        Head,
        Modal,
        ErrorsAlert,
        VueLottiePlayer,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        csrf: {
            type: String,
            required: true,
        },
        qrCode: {
            // type: String,
            required: true,
        },
        // eslint-disable-next-line vue/prop-name-casing
        general_errors: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            banks: [],
            showIbanDisclaimer: false,
            input: {
                hasImageDuplicates: false,
                payment_method: "mada",
                confirmed: null,
                images_confirmed: null,
                terms_agreed: null,
                iban: null,
                otp: null,
                bank_id: null,
                applicant_name: null,
                applicant_email: null,
                applicant_city: null,
                applicant_phone: null,
                applicant_id: 1084259546,
            },
            selectedImagesBase64: [],
            timerCount: 60,
            formIsValid: false,
            otpValidationIsLoading: false,
            timerEnabled: false,
            verificationOTPSent: false,
            resendHasError: false,
            otpHasError: false,
            isLoading: false,
            hasError: false,
            image_titles: {},
            MAX_WIDTH: 320,
            MAX_HEIGHT: 180,
            validPhone: null,
            validationsErrorMessages: [],
            MIME_TYPE: "image/jpeg",
            QUALITY: 0.7,
            companiesCodes: {
                arabia: "1988",
                khaleej: "13",
                acig: "761",
                malath: "1900",
            },
        };
    },
    computed: {
        isMobile() {
            return window.navigator.maxTouchPoints > 1;
        },
        isSafari() {
            if ("GestureEvent" in window) return true;

            return false;
        },
        errorMessages() {
            if (!this.general_errors) return;

            var errors = [];
            Object.keys(this.general_errors).forEach((key) => {
                errors.push(this.general_errors[key][0]);
            });

            return errors;
        },
    },
    watch: {
        input: {
            handler() {
                if (!this.$refs.mainForm) return;

                if (this.data.product_type_code == 1) {
                    this.formIsValid =
                    this.$refs.mainForm.checkValidity() &&
                    this.input.bank_id &&
                    this.input.iban &&
                    !this.hasError &&
                    this.input.terms_agreed;
                } else {
                    this.formIsValid =
                    this.$refs.mainForm.checkValidity() &&
                    this.input.bank_id &&
                    this.input.iban &&
                    this.input.confirmed &&
                    !this.hasError &&
                    this.input.image_front &&
                    this.input.image_back &&
                    this.input.image_left &&
                    this.input.image_right &&
                    this.input.image_vin &&
                    this.input.images_confirmed &&
                    !this.input.hasImageDuplicates.length &&
                    this.input.confirmed;
                }
            },
            deep: true,
            immediate: true,
        },
        otpHasError(value) {
            if (!value) return;
            this.verificationOTPSent = false;
        },
        verificationOTPSent(value) {
            if (!value) return;
            this.otpHasError = false;
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },

        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled && value <= 60) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else {
                    this.timerEnabled = false;
                    this.timerCount = 60;
                }
            },
            immediate: true, // This ensures the watcher is triggered upon creation
        },
    },
    methods: {
        getAdditionalCoverAmount() {
            var totalCoversAmount = 0;
            this.data.covers?.forEach((cover) => {
                totalCoversAmount = totalCoversAmount + Number(cover.amount);
            });
            return totalCoversAmount;
        },
        getAdditionalCoverTaxes() {
            var totalCoversTaxesAmount = 0;
            this.data.covers?.forEach((cover) => {
                totalCoversTaxesAmount =
                    totalCoversTaxesAmount + Number(cover.tax_amount);
            });
            return totalCoversTaxesAmount;
        },
        getVat(offer) {
            switch (this.data.company.code) {
                case this.companiesCodes["arabia"]: {
                    return (
                        this.getArabiaVat(offer) +
                        this.getAdditionalCoverTaxes(offer)
                    );
                }
                case this.companiesCodes["khaleej"]: {
                    return (
                        this.getKhlaeejVat(offer) +
                        this.getAdditionalCoverTaxes(offer)
                    );
                }
                case this.companiesCodes["malath"]: {
                    return (
                        this.getKhlaeejVat(offer) +
                        this.getAdditionalCoverTaxes(offer)
                    );
                }
                case this.companiesCodes["acig"]: {
                    return (
                        this.getAcigVat(offer) +
                        this.getAdditionalCoverTaxes(offer)
                    );
                }
                default: {
                    return 0 + this.getAdditionalCoverTaxes(offer);
                }
            }
        },
        getArabiaVat(offer) {
            return Number(
                offer.prices.find((price) => {
                    return (
                        price.price_type.english_description == "VAT" ||
                        price.price_type.code == 11
                    );
                })?.amount ?? 0
            );
        },
        getKhlaeejVat(offer) {
            return Number(
                offer.prices.find((price) => {
                    return price.price_type.code == "VAT";
                })?.amount ?? 0
            );
        },
        getAcigVat(offer) {
            return Number(offer.total_tax);
        },
        getDiscount(offer) {
            return Number(
                offer.prices.find((price) => !!price.is_discount)?.amount
            ).toFixed(2);
        },
        getMainAmount(offer) {
            switch (this.data.company.code) {
                case this.companiesCodes["arabia"]: {
                    return this.getArabiaMainAmount(offer);
                }
                case this.companiesCodes["khaleej"]: {
                    return this.getKhlaeejMainAmount(offer);
                }
                case this.companiesCodes["malath"]: {
                    return this.getKhlaeejMainAmount(offer);
                }
                case this.companiesCodes["acig"]: {
                    return this.getAcigMainAmount(offer);
                }
                default: {
                    return 0;
                }
            }
        },
        getArabiaMainAmount(offer) {
            return Number(
                offer.prices.find(
                    (price) => price.price_type.english_description == "Basic Premium"//"Gross"
                )?.amount ?? 0
            );
        },
        getKhlaeejMainAmount(offer) {
            return Number(
                offer.prices.find(
                    (price) =>
                        price.price_type.english_description == "Basic Premium"
                )?.amount ?? 0
            );
        },
        getAcigMainAmount(offer) {
            return Number(offer.gross_premium);
        },
        getAddonsAndDiscounts(offer) {
            switch (this.data.company.code) {
                case this.companiesCodes["arabia"]: {
                    return this.getArabiaAddonsAndDiscounts(offer);
                }
                case this.companiesCodes["khaleej"]: {
                    return this.getKhlaeejAddonsAndDiscounts(offer);
                }
                case this.companiesCodes["malath"]: {
                    return this.getKhlaeejAddonsAndDiscounts(offer);
                }
                case this.companiesCodes["acig"]: {
                    return this.getAcigAddonsAndDiscounts(offer);
                }
                default: {
                    return [];
                }
            }
        },
        getArabiaAddonsAndDiscounts(offer) {
            let items = [];
            offer.prices?.forEach(function (price) {
                if (
                    price.price_type.english_description != "VAT" &&
                    price.price_type.code != 11 &&
                    price.price_type.english_description != "Gross"
                ) {
                    items.push(price);
                }
            });
            return items;
        },
        getKhlaeejAddonsAndDiscounts(offer) {
            let items = [];
            offer.prices?.forEach(function (price) {
                if (
                    price.price_type.code != "VAT" &&
                    price.price_type.english_description != "Basic Premium"
                ) {
                    items.push(price);
                }
            });
            return items;
        },
        getAcigAddonsAndDiscounts(offer) {
            let items = [];
            offer.prices?.forEach(function (price) {
                if (price.is_discount == 1) {
                    items.push(price);
                }
            });
            return items;
        },
        getTotalAmount(offer) {
            switch (this.data.company.code) {
                case this.companiesCodes["arabia"]: {
                    return (
                        this.getArabiaTotalAmount(offer) +
                        this.getAdditionalCoverAmount(offer)
                    );
                }
                case this.companiesCodes["khaleej"]: {
                    return (
                        this.getKhlaeejTotalAmount(offer) +
                        this.getAdditionalCoverAmount(offer)
                    );
                }
                case this.companiesCodes["malath"]: {
                    return (
                        this.getKhlaeejTotalAmount(offer) +
                        this.getAdditionalCoverAmount(offer)
                    );
                }
                case this.companiesCodes["acig"]: {
                    return (
                        this.getAcigTotalAmount(offer) +
                        this.getAdditionalCoverAmount(offer)
                    );
                }
                default: {
                    return 0 + this.getAdditionalCoverAmount(offer);
                }
            }
        },
        getArabiaTotalAmount(offer) {
            var mainAmount = this.getArabiaMainAmount(offer);
            var addonsAndDiscounts = this.getArabiaAddonsAndDiscounts(offer);
            addonsAndDiscounts?.forEach(function (item) {
                if (item.is_discount) {
                    mainAmount = mainAmount - parseFloat(item.amount);
                } else {
                    mainAmount = mainAmount + parseFloat(item.amount);
                }
            });
            return Number(mainAmount);
        },
        getKhlaeejTotalAmount(offer) {
            var mainAmount = this.getKhlaeejMainAmount(offer);
            var addonsAndDiscounts = this.getKhlaeejAddonsAndDiscounts(offer);
            addonsAndDiscounts?.forEach(function (item) {
                if (item.is_discount) {
                    mainAmount = mainAmount - parseFloat(item.amount);
                } else {
                    mainAmount = mainAmount + parseFloat(item.amount);
                }
            });
            return Number(mainAmount);
        },
        getAcigTotalAmount(offer) {
            var mainAmount = this.getAcigMainAmount(offer);
            var addonsAndDiscounts = this.getAcigAddonsAndDiscounts(offer);
            addonsAndDiscounts?.forEach(function (item) {
                if (item.is_discount) {
                    mainAmount = mainAmount - parseFloat(item.amount);
                } else {
                    mainAmount = mainAmount + parseFloat(item.amount);
                }
            });
            return Number(mainAmount);
        },
        
        async resendCode() {
            this.verificationOTPSent = false;
            this.timerEnabled = true;
            this.resendHasError = false;
            const payload = {
                mobile: this.input.applicant_phone,
                order_id: this.data.id,
                _token: this.csrf,
            };

            this.otpHasError = false;

            await axios
                .post(route("send.otp.for.order"), payload)
                .then(() => {
                    this.verificationOTPSent = true;
                })
                .catch((err) => {
                    console.error(err);
                    this.resendHasError = true;
                });
        },
        async validateOTP() {
            const payload = {
                mobile: this.input.applicant_phone,
                order_id: this.data.id,
                otp: this.input.otp,
                _token: this.csrf,
            };
            this.otpHasError = false;

            await axios
                .post(route("verify.otp.for.order"), payload)
                .then((res) => {
                    if (res.data.success) {
                        this.$refs.otpModal.toggleModal();
                        this.$refs.pay.submit();
                        this.isLoading = true;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.otpHasError = true;
                });
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
        validateLength(e, key, isMobile = false) {
            const input = e.target;
            const fieldset = e.target.closest("fieldset");
            var value = this.input[key].toString();
            let maxLength = input.maxLength;

            if (isMobile) {
                let phoneNumber = value.toString();

                if (phoneNumber.startsWith('+9665')) {
                    maxLength = 13;
                }else if (phoneNumber.startsWith('9665')) {
                    maxLength = 12;
                } else if (phoneNumber.startsWith('05')) {
                    maxLength = 10;
                } else if (phoneNumber.startsWith('5')) {
                    maxLength = 9;
                } else {
                    maxLength = 8;
                }
            }

            if (value.length < maxLength) {
                this.hasError = true;
                fieldset.classList.add("border-danger");
            } else if (value.length > maxLength)
                this.input[key] = this.input[key]
                    .toString()
                    .slice(0, maxLength);
            else if (value.length == maxLength) {
                fieldset.classList.remove("border-danger");
                this.hasError = false;
            }
        },
        showQRCode() {
            this.$refs.QrCodeModal.toggleModal();
        },
        setImage(e, key) {
            var file = e.target.files[0] || e.dataTransfer.files[0];
            if (!file) return;
            var vm = this;
            var blobURL = URL.createObjectURL(file);
            var img = new Image();
            img.src = blobURL;
            img.onerror = function () {
                URL.revokeObjectURL(img.src);
                // Handle the failure properly
                console.log("Cannot load image");
            };

            img.onload = () => {
                URL.revokeObjectURL(img.src);
                var [newWidth, newHeight] = this.calculateSize(
                    img,
                    this.MAX_WIDTH,
                    this.MAX_HEIGHT
                );
                var canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                // compare image to catch duplicates
                if (vm.selectedImagesBase64.length > 0) {
                    const currentImage = canvas.toDataURL("image/png").replace(/^data:image\/(png|jpg);base64,/, "")
                    // reset the boolean
                    vm.input.hasImageDuplicates = false;
                    vm.selectedImagesBase64.forEach(image => {
                        if(currentImage == image) {
                            vm.input.hasImageDuplicates = true;
                        }
                    })
                    vm.selectedImagesBase64.push(currentImage);
                } else {
                    var dataURL = canvas.toDataURL("image/png");
                    vm.selectedImagesBase64.push(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
                }
                canvas.toBlob(
                    (blob) => {
                        // Handle the compressed image. es. upload or save in local state
                        // displayInfo('Original file', file);
                        // displayInfo('Compressed file', blob);
                        // console.log(file);
                        vm.input[key] = blob;
                    },
                    this.MIME_TYPE,
                    this.QUALITY
                );
                var previewCanvas = document.createElement("canvas");
                // eslint-disable-next-line no-redeclare
                var [newWidth, newHeight] = this.calculateSize(img, 100, 100);
                previewCanvas.width = newWidth;
                previewCanvas.height = newHeight;
                var previewCtx = previewCanvas.getContext("2d");
                previewCtx.drawImage(img, 0, 0, newWidth, newHeight);
                previewCanvas.toBlob(
                    (blob) => {
                        // Handle the compressed image. es. upload or save in local state
                        return blob;
                    },
                    this.MIME_TYPE,
                    this.QUALITY
                );
                vm.$refs[key].innerHTML = "";
                vm.$refs[`${key}_tag`].style.display = "none";
                vm.$refs[`${key}_done`].style.display = "block";
                vm.$refs[`${key}_camera`].style.display = "none";
                vm.$refs[key].appendChild(previewCanvas);
            };

            // this.input[key] = file[0].name;
        },
        calculateSize(img, maxWidth, maxHeight) {
            var width = img.width;
            var height = img.height;

            // calculate the width and height, constraining the proportions
            if (width > height) {
                if (width > maxWidth) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }
            return [width, height];
        },
        async payNow() {
            this.isLoading = true;
            this.validationsErrorMessages = [];

            let phoneNumber = this.input.applicant_phone;

            if (phoneNumber.startsWith('9665')) {
                phoneNumber = phoneNumber.slice(3);
            }else if (phoneNumber.startsWith('+9665')) {
                phoneNumber = phoneNumber.slice(4);
            } else if (phoneNumber.startsWith('05')) {
                phoneNumber = phoneNumber.slice(1);
            }

            this.validPhone = `0${phoneNumber}`;

            await axios.post(route("verify.mobile.number"), {
                    identity_id: this.data.owner.id,
                    vehicle_id: this.data.vehicle.id,
                    mobile_number: phoneNumber,
                    email: this.input.applicant_email,
                })
                .then(response => {
                    const { data } = response;
                    if (!data.data) {
                        data.message ? this.validationsErrorMessages.push(data.message) : '';
                    }

                    if (data.data) {
                        this.$refs.otpModal.toggleModal();
                        this.resendCode();
                    }

                    this.isLoading = false;
                })
        },
    },
    async mounted() {
        this.input.applicant_name = this.data.owner.arabic_full_name;
        this.input.iban = this.data.owner.iban?.slice(2);
        this.input.applicant_id = this.data.owner.number;
        this.input.applicant_city = this.data.owner.identity_address.city;
        this.input.applicant_email = this.user.email;
        this.input.applicant_phone = this.user.mobile;

        this.input.confirmed = false;
        this.input.terms_agreed = false;

        await fetch(route("lookups.index", "bank_codes"))
            .then((res) => res.json())
            .then((data) => {
                this.banks = data.data;
            });

        await axios.get(route("lookups.index", "image_title")).then((res) => {
            const { data } = res;
            const res_image_titles = data.data;

            res_image_titles.forEach((item) => {
                const key = item.describtion.toLowerCase().split(" ").join("_");
                this.image_titles[key] = item.id_code;
                this.input[key] = null;
            });
        });
    },
};
</script>
